import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Input,
    DatePicker,
    InputNumber,
    Button,
    Table,
    Form,
    Modal,
    notification,
    Radio,
    Checkbox,
    Switch,
    Upload,
    Popconfirm,
} from 'antd'
import { goBack } from '../../Util/ILUtil'
import {
    CheckOutlined,
    CloseOutlined,
    DeleteTwoTone,
    UploadOutlined,
} from '@ant-design/icons'
import clone from 'clone'
import Paper from '@mui/material/Paper'

function CreateSpecialCoupons({ navigate }) {
    const [form] = Form.useForm()
    const { RangePicker } = DatePicker
    const { Search } = Input
    const { Dragger } = Upload

    const [couponTypes, setCouponTypes] = useState([])
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [selectedCourses, setSelectedCourses] = useState([])
    const [coursesEnabled, setCoursesEnabled] = useState(true)
    const [selectedAttempts, setSelectedAttempts] = useState([])
    const [attemptsEnabled, setAttemptsEnabled] = useState(true)
    const [loading, setLoading] = useState(false)
    const [selectCourseVisible, setSelectCourseVisible] = useState(false)
    const [selectAttemptVisible, setSelectAttemptVisible] = useState(false)
    const [courses, setCourses] = useState([])
    const [attempts, setAttempts] = useState([])
    const [fileList, setFileList] = useState([])

    useEffect(() => {
        RestApi.doGet(GLOBAL.URL.GET_COUPON_TYPES).then((res) => {
            setCouponTypes(res.data)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const getCouponTypeView = () => {
        var couponTypeView = []
        couponTypes.forEach((item) => {
            couponTypeView.push(
                <Radio.Button value={item} key={item}>
                    {item.toUpperCase()}
                </Radio.Button>
            )
        })

        return couponTypeView
    }

    const onChangeDateRange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const showSelectCourse = () => {
        setSelectCourseVisible(true)
    }

    const hideSelectCourse = () => {
        setSelectCourseVisible(false)
    }

    const searchCatalogCourse = (query) => {
        if (!query) {
            return
        }

        setLoading(true)

        var payload = {}
        payload.query = query ? query : ''

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.COURSE.SEARCH_CATALOG_COURSES, formData)
            .then((res) => {
                setCourses(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const searchCourseColumns = [
        {
            title: 'Select',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            render: (text, record) => (
                <Button
                    type="primary"
                    size="small"
                    onClick={() => selectCourse(record)}
                >
                    Select
                </Button>
            ),
        },
        {
            title: 'Course Name',
            dataIndex: 'name',
            key: 'name',
        },
    ]

    const selectCourse = (record) => {
        var courseFound = false
        selectedCourses.forEach((course) => {
            if (course.courseId === record.courseId) {
                courseFound = true
                return
            }
        })

        if (!courseFound) {
            var data1 = clone(selectedCourses)
            data1.push(record)

            setSelectedCourses(data1)
        }

        hideSelectCourse()
    }

    const courseColumns = [
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            width: 40,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        removeCourse(record.courseId)
                    }}
                >
                    <DeleteTwoTone
                        twoToneColor="#eb2f96"
                        style={{ fontSize: 20 }}
                    />
                </Button>
            ),
        },
        {
            title: 'Course Name',
            dataIndex: 'name',
            key: 'name',
        },
    ]

    const removeCourse = (courseId) => {
        var data1 = clone(selectedCourses)
        for (var i = 0; i < data1.length; i++) {
            if (data1[i].courseId === courseId) {
                data1.splice(i, 1)
            }
        }

        setSelectedCourses(data1)
    }

    const showSelectAttempt = () => {
        if (!attempts.length) {
            setLoading(true)
            RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS)
                .then((res) => {
                    setAttempts(res.data)
                })
                .finally(() => {
                    setLoading(false)
                })
        }

        setSelectAttemptVisible(true)
    }

    const hideSelectAttempt = () => {
        setSelectAttemptVisible(false)
    }

    const selectedAttemptsColums = [
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            width: 40,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        removeSelectedAttempt(record)
                    }}
                >
                    <DeleteTwoTone
                        twoToneColor="#eb2f96"
                        style={{ fontSize: 20 }}
                    />
                </Button>
            ),
        },
        {
            title: 'Attempt',
            dataIndex: 'attempt',
            key: 'attempt',
        },
    ]

    const removeSelectedAttempt = (attemptObj) => {
        var selectedAttemptNames1 = clone(selectedAttempts)
        for (var i = 0; i < selectedAttemptNames1.length; i++) {
            if (selectedAttemptNames1[i].attempt === attemptObj.attempt) {
                selectedAttemptNames1.splice(i, 1)
                break
            }
        }

        setSelectedAttempts(selectedAttemptNames1)
        hideSelectAttempt()
    }

    const searchAttemptColumns = [
        {
            title: 'Select',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            render: (text, record) => (
                <Button
                    type="primary"
                    size="small"
                    onClick={() => selectAttempt(record)}
                >
                    Select
                </Button>
            ),
        },
        {
            title: 'Attempt',
            dataIndex: 'attempt',
            key: 'attempt',
        },
    ]

    const selectAttempt = (record) => {
        var attemptFound = false
        selectedAttempts.forEach((course) => {
            if (course.attempt === record.attempt) {
                attemptFound = true
                return
            }
        })

        if (!attemptFound) {
            var data1 = clone(selectedAttempts)
            data1.push(record)

            setSelectedAttempts(data1)
        }

        hideSelectAttempt()
    }

    const uploadProps = {
        multiple: false,
        onRemove: (file) => {
            const index = fileList.indexOf(file)
            const newFileList = fileList.slice()
            newFileList.splice(index, 1)
            setFileList(newFileList)
        },
        beforeUpload: (file) => {
            setFileList([file])
            return false
        },
        fileList,
    }

    const createCoupons = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                if (!startDate) {
                    notification['error']({
                        message: 'Start date needed!',
                    })

                    return
                }

                if (!endDate) {
                    notification['error']({
                        message: 'End date needed!',
                    })

                    return
                }

                if (!fileList.length) {
                    notification['error']({
                        message: 'Select the file with emails for upload!',
                    })

                    return
                }

                var courseIds = []
                selectedCourses.forEach((course) => {
                    courseIds.push(course.courseId)
                })

                var attempts = []
                selectedAttempts.forEach((attempt) => {
                    attempts.push(attempt.attempt)
                })

                values.startDate = startDate
                values.endDate = endDate
                values.courseIds = courseIds
                values.coursesEnabled = coursesEnabled
                values.attempts = attempts
                values.attemptsEnabled = attemptsEnabled

                let body = new FormData()
                body.append('payload', JSON.stringify(values))
                body.append('file', fileList[0])

                RestApi.doPost(GLOBAL.URL.CREATE_SPECIAL_COUPONS, body)
                    .then((res) => {
                        notification['success']({
                            message: res.data,
                        })
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteAllSpecialCoupons = () => {
        setLoading(true)

        RestApi.doPost(GLOBAL.URL.DELETE_ALL_SPECIAL_COUPONS, new FormData())
            .then((res) => {
                notification['success']({
                    message: res.data,
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            <div>
                <Button size="small" onClick={() => goBack(navigate)}>
                    {'< Back'}
                </Button>
                <Popconfirm
                    title="Are you sure to delete all special coupons?"
                    onConfirm={deleteAllSpecialCoupons}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="default"
                        loading={loading}
                        style={{ marginLeft: 20 }}
                        size="small"
                    >
                        Delete All Special Coupons
                    </Button>
                </Popconfirm>
            </div>
            <h2>Create Special Coupons</h2>
            <Form form={form} layout="horizontal" {...formItemLayout}>
                <Form.Item
                    label="Coupon Type"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="couponType"
                    rules={[
                        {
                            required: true,
                            message: 'Needed!',
                        },
                    ]}
                >
                    <Radio.Group>{getCouponTypeView()}</Radio.Group>
                </Form.Item>
                <Form.Item
                    label="Discount"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="discount"
                    rules={[
                        {
                            required: true,
                            message: 'Needed!',
                        },
                    ]}
                >
                    <InputNumber min={0} />
                </Form.Item>
                <Form.Item
                    label="Date range"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="dateRange"
                    rules={[
                        {
                            required: true,
                            message: 'Needed!',
                        },
                    ]}
                >
                    <RangePicker
                        showTime={{ format: 'HH:mm' }}
                        format={'DD-MMM-YY HH:mm'}
                        placeholder={['Start Date', 'End Date']}
                        onChange={onChangeDateRange}
                    />
                </Form.Item>
                <Form.Item
                    label="Max uses #"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="limit"
                    rules={[
                        {
                            required: true,
                            message: 'Needed!',
                        },
                    ]}
                >
                    <InputNumber min={0} />
                </Form.Item>
                <Form.Item
                    label="Once Per User"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="oncePerUser"
                    valuePropName="checked"
                >
                    <Checkbox></Checkbox>
                </Form.Item>
                <Form.Item
                    label="Default Coupon Name"
                    style={{ marginBottom: 10 }}
                    name="defaultCouponName"
                    rules={[
                        {
                            required: true,
                            message: 'Needed!',
                        },
                    ]}
                >
                    <Input style={{ width: 200 }} />
                </Form.Item>
                <Paper elevation={2} style={{ padding: 10, marginTop: 10 }}>
                    <ul>
                        <li>{'Have only 1 sheet in the excel file'}</li>
                        <li>
                            {
                                'Have a column with title Email (Do not have formulae)'
                            }
                        </li>
                    </ul>
                    <Dragger {...uploadProps}>
                        <Button>
                            <UploadOutlined />
                            {
                                'Click or Drag file to this area to add attachment'
                            }
                        </Button>
                    </Dragger>
                </Paper>
            </Form>
            <h3 style={{ marginTop: 25 }}>Course Mappings</h3>
            <div style={{ marginTop: 10 }}>
                <span>Enabled: </span>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={coursesEnabled}
                    onClick={(enabled) => setCoursesEnabled(enabled)}
                    disabled={loading}
                />
                <Button style={{ marginLeft: 40 }} onClick={showSelectCourse}>
                    Add Course
                </Button>
                <Table
                    columns={courseColumns}
                    dataSource={selectedCourses}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="name"
                    style={{ marginTop: 10 }}
                />
            </div>
            <h3 style={{ marginTop: 25 }}>Attempt Mappings</h3>
            <div style={{ marginTop: 10 }}>
                <span>Enabled: </span>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={attemptsEnabled}
                    onClick={(enabled) => setAttemptsEnabled(enabled)}
                    disabled={loading}
                />
                <Button style={{ marginLeft: 40 }} onClick={showSelectAttempt}>
                    Add Attempt
                </Button>
                <Table
                    columns={selectedAttemptsColums}
                    dataSource={selectedAttempts}
                    pagination={false}
                    loading={loading}
                    size="small"
                    style={{ marginTop: 10 }}
                />
            </div>
            <div style={{ marginTop: 10 }}>
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={createCoupons}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="primary" loading={loading}>
                        Create Sepcial Coupons
                    </Button>
                </Popconfirm>
            </div>
            <Modal
                title={'Select Course'}
                open={selectCourseVisible}
                onCancel={hideSelectCourse}
                destroyOnClose={true}
                width={900}
                footer={null}
            >
                <Search
                    placeholder="Search Course"
                    onSearch={(value) => searchCatalogCourse(value)}
                />
                <Table
                    columns={searchCourseColumns}
                    dataSource={courses}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="name"
                    style={{ marginTop: 10 }}
                    scroll={{ y: 400 }}
                />
            </Modal>
            <Modal
                title={'Select Attempt'}
                open={selectAttemptVisible}
                onCancel={hideSelectAttempt}
                destroyOnClose={true}
                width={500}
                footer={null}
            >
                <Table
                    columns={searchAttemptColumns}
                    dataSource={attempts}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="attempt"
                    style={{ marginTop: 10 }}
                    scroll={{ y: 400 }}
                />
            </Modal>
        </div>
    )
}

export default CreateSpecialCoupons
