import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    notification,
    Form,
    Radio,
    Select,
    Checkbox,
} from 'antd'
import {
    EditOutlined,
    LoadingOutlined,
    ReloadOutlined,
} from '@ant-design/icons'
import clone from 'clone'
import { Link } from '@mui/material'
import TinyMceEditor from '../Common/TinyMceEditor'
import ILAttachmentFileList from '../ILShareComponents/ILAttachmentFileList'
import { useSearchParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'

function Tickets() {
    const { Option } = Select
    const [form] = Form.useForm()
    const { Search } = Input

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [nextUrl, setNextUrl] = useState()
    const [count, setCount] = useState(0)
    const [ticketStatusList, setTicketStatusList] = useState([])
    const [selectedStatus, setSelectedStatus] = useState()
    const [assignedToFilter, setAssignedToFilter] = useState()
    const [selectedRecord, setSelectedRecord] = useState({})
    const [addUpdateModalVisible, setAddUpdateModalVisible] = useState(false)
    const [employees, setEmployees] = useState([])
    const [updateStatusModalVisible, setUpdateStatusModalVisible] =
        useState(false)
    const [assignToModalVisible, setAssignToModalVisible] = useState(false)
    const [description, setDescription] = useState('')
    const [attachmentFileList, setAttachmentFileList] = useState([])
    const [linkedUser, setLinkedUser] = useState({})
    const [linkUserModalVisible, setLinkUserModal] = useState(false)
    const [searchUserLoading, setSearchUserLoading] = useState(false)
    const [searchUserData, setSearchUserData] = useState([])
    const [openStatsVisible, setOpenStatsVisible] = useState(false)
    const [openStats, setOpenStats] = useState('')
    const [loadingStats, setLoadingStats] = useState(false)
    const [isPrivate, setIsPrivate] = useState(false)

    const [searchParams] = useSearchParams()
    const [filterStudent, setFilterStudent] = useState()
    const [filterStudentSearch, setFilterStudentSearch] = useState(false)

    useEffect(() => {
        getTicketStatusList()
        getEmployees()
        checkAndGetFilterStudent()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!selectedStatus) {
            return
        }

        getTickets()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStatus, assignedToFilter, filterStudent])

    const getTicketStatusList = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.TICKETS.GET_STATUS_LIST).then((res) => {
            setTicketStatusList(res.data)
            setSelectedStatus(res.data[0].keyName)
        })
    }

    const getEmployees = () => {
        RestApi.doGet(GLOBAL.URL.IL.GET_EMPLOYEES + 'active').then((res) => {
            setEmployees(res.data)
        })
    }

    const checkAndGetFilterStudent = () => {
        const studentId = searchParams.get('studentId')
        if (!studentId) {
            return
        }

        RestApi.doGet(GLOBAL.URL.GET_USER_BY_ID + studentId).then((res) => {
            setFilterStudent(res.data)
        })
    }

    const getTickets = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.TICKETS.GET +
                '?status=' +
                selectedStatus +
                '&assignedTo=' +
                (assignedToFilter ? assignedToFilter : '') +
                '&userId=' +
                (filterStudent && filterStudent.id ? filterStudent.id : '')
        )
            .then((response) => {
                var res = JSON.parse(response.data)
                setData(res.data)
                setNextUrl(res.next)
                setCount(res.count)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageTickets = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onChangeStatusFilter = (e) => {
        setCount(0)
        setSelectedStatus(e.target.value)
    }

    const getStatusFilterOptions = (showCount = true) => {
        var options = []

        ticketStatusList.forEach((row) => {
            options.push(
                <Radio.Button value={row.keyName} key={row.keyName}>
                    {row.value +
                        (showCount && selectedStatus === row.keyName && count
                            ? ' (' + count + ')'
                            : '')}
                </Radio.Button>
            )
        })

        return options
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <Button
                    size={'small'}
                    onClick={() => showUpdateStatusModal(record)}
                >
                    {record.status.value}
                </Button>
            ),
        },
        {
            title: 'Public',
            dataIndex: 'public',
            key: 'public',
            render: (text, record) => (
                <>
                    {record.forumTopic ? null : !record.private ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                </>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => (
                <span>
                    {!record.forumTopic ? (
                        <Button
                            type="link"
                            onClick={() => {
                                showAddUpdateModal(record)
                            }}
                            style={{ padding: 0, marginRight: 5 }}
                        >
                            <EditOutlined
                                theme="twoTone"
                                style={{ fontSize: 9 }}
                            />
                        </Button>
                    ) : null}
                    <Link
                        onClick={(event) => {
                            event.preventDefault()
                            window.open('/ticket/' + record.uid, '_blank')
                        }}
                        underline="none"
                    >
                        {record.forumTopic
                            ? '[Forum - ' +
                              record.forumTopic.groupName +
                              '] ' +
                              record.forumTopic.title
                            : record.title}
                    </Link>
                </span>
            ),
        },
        {
            title: 'Assigned To',
            dataIndex: 'assignedTo',
            key: 'assignedTo',
            render: (text, record) => (
                <Button
                    type={'link'}
                    style={{ padding: 0 }}
                    onClick={() => showAssignToModal(record)}
                >
                    {record.assignedTo ? (
                        record.assignedTo.firstName
                    ) : (
                        <EditOutlined
                            theme="twoTone"
                            style={{ fontSize: 11 }}
                        />
                    )}
                </Button>
            ),
        },
        {
            title: 'Student',
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => (
                <>
                    {record.linkedUser ? (
                        <Link
                            href={'/userdetails/' + record.linkedUser.id}
                            onClick={(event) => {
                                event.preventDefault()
                                window.open(
                                    '/userdetails/' + record.linkedUser.id,
                                    '_blank'
                                )
                            }}
                            underline="none"
                        >
                            {record.linkedUser.firstName +
                                ' ' +
                                record.linkedUser.lastName}
                        </Link>
                    ) : null}
                </>
            ),
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'createdBy',
            render: (text, record) => (
                <span>
                    <span style={{ fontSize: 12 }}>
                        {record.createdBy.firstName}
                        <br />
                        {record.createdAt}
                    </span>
                </span>
            ),
        },
    ]

    const showAddUpdateModal = (record = {}) => {
        setSelectedRecord(record)
        setAddUpdateModalVisible(true)
        setIsPrivate(false)

        if (filterStudent && filterStudent.id) {
            setLinkedUser(filterStudent)
        } else {
            setLinkedUser({})
        }

        if (record.uid) {
            setDescription(record.description)
            setTimeout(() => {
                form.setFieldsValue({
                    title: record.title,
                })
            }, 100)
        } else {
            setDescription('')
            form.resetFields()
        }
    }

    const hideAddUpdateModal = () => {
        setAddUpdateModalVisible(false)
        setSelectedRecord({})
        setAttachmentFileList([])
        setLinkedUser({})
    }

    const getEmployeeOptions = () => {
        var options = []
        employees.forEach((employee) => {
            options.push(
                <Option value={employee.user.id} key={employee.user.id}>
                    {employee.user.firstName + ' ' + employee.user.lastName}
                </Option>
            )
        })

        return options
    }

    const onSelectEmployee = (value) => {
        setAssignedToFilter(value)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addUpdateTicket = () => {
        form.validateFields(['title', 'assignedTo'])
            .then((values) => {
                if (!description) {
                    notification['error']({
                        message: 'Description needed',
                    })
                    return
                }

                setLoading(true)

                values.description = description
                values.private = isPrivate

                if (linkedUser && linkedUser.id) {
                    values.linkedUserId = linkedUser.id
                }

                var url = GLOBAL.URL.TICKETS.ADD
                if (selectedRecord.uid) {
                    url = GLOBAL.URL.TICKETS.UPDATE
                    values.uid = selectedRecord.uid
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                attachmentFileList.forEach((file) => {
                    formData.append('file', file.originFileObj)
                })

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddUpdateModal()
                        getTickets()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const showUpdateStatusModal = (record) => {
        setUpdateStatusModalVisible(true)
        setSelectedRecord(record)
        setTimeout(() => {
            form.setFieldsValue({ status: record.status.keyName })
        }, 100)
    }

    const hideUpdateStatusModal = () => {
        setUpdateStatusModalVisible(false)
        setSelectedRecord({})
    }

    const updateStatus = () => {
        form.validateFields(['status'])
            .then((values) => {
                setLoading(true)

                values.uid = selectedRecord.uid

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.TICKETS.UPDATE_STATUS, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideUpdateStatusModal()
                        getTicket(values.uid)
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const getTicket = (uid) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.TICKETS.GET_TICKET_BY_ID + uid)
            .then((res) => {
                var fullData = clone(data)
                fullData.forEach((item, index) => {
                    if (item.uid === uid) {
                        fullData[index] = res.data
                        return
                    }
                })

                setData(fullData)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAssignToModal = (record) => {
        setSelectedRecord(record)
        setAssignToModalVisible(true)

        setTimeout(() => {
            if (record.assignedTo) {
                form.setFieldsValue({ assignedTo: record.assignedTo.id })
            } else {
                form.resetFields()
            }
        }, 100)
    }

    const hideAssignToModal = () => {
        setAssignToModalVisible(false)
    }

    const assignTicket = () => {
        form.validateFields(['assignedTo'])
            .then((values) => {
                setLoading(true)

                values.uid = selectedRecord.uid

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.TICKETS.ASSIGN, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAssignToModal()
                        getTicket(values.uid)
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const handleDescriptionChange = (newValue, e) => {
        setDescription(newValue)
    }

    const showLinkedUserModal = (search) => {
        setFilterStudentSearch(search)
        setLinkUserModal(true)
    }

    const hideLinkedUserModal = () => {
        setLinkUserModal(false)
    }

    const searchUser = (query) => {
        setSearchUserLoading(true)

        var payload = {}
        payload.query = query ? query : ''

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.SEARCH_USER, formData)
            .then((res) => {
                setSearchUserData(res.data)
            })
            .finally(() => {
                setSearchUserLoading(false)
            })
    }

    const selectUserInSearch = (record) => {
        if (filterStudentSearch) {
            setFilterStudent(record)
        } else {
            setLinkedUser(record)
        }

        hideLinkedUserModal()
    }

    const searchUserColumns = [
        {
            title: 'Add',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record) => (
                <Button
                    type="primary"
                    size="small"
                    onClick={() => selectUserInSearch(record)}
                >
                    Select
                </Button>
            ),
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
        },
    ]

    const showOpenStats = () => {
        setOpenStatsVisible(true)
        getOpenStats()
    }

    const hideOpenStats = () => {
        setOpenStatsVisible(false)
    }

    const getOpenStats = () => {
        setLoadingStats(true)

        RestApi.doGet(GLOBAL.URL.TICKETS.GET_OPEN_TICKET_STATS)
            .then((res) => {
                setOpenStats(res.data)
            })
            .finally(() => {
                setLoadingStats(false)
            })
    }

    const createMarkupWeb = (htmlContent) => {
        return { __html: htmlContent }
    }

    return (
        <div>
            <h2>Tickets</h2>
            <div>
                <Radio.Group
                    onChange={onChangeStatusFilter}
                    value={selectedStatus}
                    style={{ marginBottom: 10 }}
                >
                    {getStatusFilterOptions()}
                </Radio.Group>
                <Select
                    showSearch
                    allowClear={true}
                    filterOption={(input, option) =>
                        option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: 200, marginLeft: 20 }}
                    placeholder={'Agent'}
                    onChange={onSelectEmployee}
                    value={assignedToFilter}
                >
                    {getEmployeeOptions()}
                </Select>
                <Button
                    type="default"
                    style={{
                        width: 200,
                        marginLeft: 20,
                        justifyContent: 'left',
                    }}
                    onClick={() => showLinkedUserModal(true)}
                >
                    {filterStudent && filterStudent.id ? (
                        filterStudent.firstName + ' ' + filterStudent.lastName
                    ) : (
                        <span style={{ color: 'rgba(0, 0, 0, 0.25)' }}>
                            Student
                        </span>
                    )}
                </Button>
                {filterStudent && filterStudent.id ? (
                    <Button
                        type="link"
                        style={{
                            padding: 0,
                            marginLeft: 5,
                        }}
                        onClick={() => setFilterStudent()}
                    >
                        <FontAwesomeIcon
                            icon={faTimes}
                            color="rgba(0, 0, 0, 0.25)"
                        />
                    </Button>
                ) : null}
            </div>
            <div>
                <Button type="primary" onClick={showAddUpdateModal}>
                    Add Ticket
                </Button>
                <Button style={{ marginLeft: 10 }} onClick={showOpenStats}>
                    Open Stats
                </Button>
                <Button
                    type="link"
                    style={{ padding: 0, marginLeft: 10 }}
                    onClick={() => getTickets()}
                >
                    {loading ? (
                        <LoadingOutlined style={{ fontSize: 18 }} />
                    ) : (
                        <ReloadOutlined style={{ fontSize: 18 }} />
                    )}
                </Button>
            </div>
            <div>
                <Table
                    columns={tableColumns}
                    dataSource={data}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
                {nextUrl ? (
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={getNextPageTickets}
                        size="small"
                        style={{ marginTop: 10 }}
                    >
                        Show More
                    </Button>
                ) : null}
            </div>
            <Modal
                title={(selectedRecord.uid ? 'Update' : 'Add') + ' Ticket'}
                open={addUpdateModalVisible}
                onOk={addUpdateTicket}
                confirmLoading={loading}
                onCancel={hideAddUpdateModal}
                okText={selectedRecord.uid ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={900}
                maskClosable={false}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                        style={{ marginBottom: 0 }}
                    >
                        <Input />
                    </Form.Item>
                    {!selectedRecord.uid ? (
                        <>
                            <Form.Item
                                label="Assign To"
                                name="assignedTo"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                style={{ marginTop: 10 }}
                            >
                                <Select
                                    showSearch
                                    allowClear={true}
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: 350 }}
                                >
                                    {getEmployeeOptions()}
                                </Select>
                            </Form.Item>
                            <div>
                                <span style={{ marginLeft: 33 }}>
                                    Linked Student:{' '}
                                </span>
                                <Button
                                    type="link"
                                    style={{ padding: 0, marginLeft: 5 }}
                                    onClick={() => showLinkedUserModal(false)}
                                >
                                    {linkedUser.id
                                        ? linkedUser.firstName +
                                          ' ' +
                                          linkedUser.lastName
                                        : '--Select--'}
                                </Button>
                            </div>
                            <div style={{ marginTop: 5 }}>
                                <span style={{ marginLeft: 86 }}>
                                    {'Private:'}
                                </span>
                                <Checkbox
                                    onChange={(event) => {
                                        setIsPrivate(event.target.checked)
                                    }}
                                    style={{ marginLeft: 10 }}
                                ></Checkbox>
                            </div>
                        </>
                    ) : null}
                    <div>
                        <h4>Description</h4>
                        <TinyMceEditor
                            height={400}
                            handleEditorChange={handleDescriptionChange}
                            initialValue={description}
                            uploadGroupName={'tickets'}
                        />
                        <ILAttachmentFileList
                            attachmentFileList={attachmentFileList}
                            setAttachmentFileList={setAttachmentFileList}
                        />
                    </div>
                </Form>
            </Modal>
            <Modal
                title={'Update Status'}
                open={updateStatusModalVisible}
                onOk={updateStatus}
                confirmLoading={loading}
                onCancel={hideUpdateStatusModal}
                okText={'Update'}
                destroyOnClose={true}
                width={400}
            >
                <p>{selectedRecord.title}</p>
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label=""
                        name="status"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group>
                            {getStatusFilterOptions(false)}
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Assign Ticket'}
                open={assignToModalVisible}
                onOk={assignTicket}
                confirmLoading={loading}
                onCancel={hideAssignToModal}
                okText={'Update'}
                destroyOnClose={true}
                width={400}
            >
                <p>{selectedRecord.title}</p>
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label=""
                        name="assignedTo"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 350 }}
                        >
                            {getEmployeeOptions()}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Select User'}
                open={linkUserModalVisible}
                onCancel={hideLinkedUserModal}
                destroyOnClose={true}
                width={900}
                footer={null}
            >
                <Search
                    placeholder="Search Student"
                    onSearch={(value) => searchUser(value)}
                    autoFocus
                />
                <Table
                    columns={searchUserColumns}
                    dataSource={searchUserData}
                    pagination={false}
                    size="small"
                    loading={searchUserLoading}
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={''}
                open={openStatsVisible}
                onCancel={hideOpenStats}
                destroyOnClose={true}
                width={400}
                footer={null}
            >
                <h2>
                    {'Open Stats'}
                    <Button
                        type="link"
                        style={{ padding: 0, marginLeft: 10 }}
                        onClick={getOpenStats}
                    >
                        {loadingStats ? (
                            <LoadingOutlined style={{ fontSize: 16 }} />
                        ) : (
                            <ReloadOutlined style={{ fontSize: 16 }} />
                        )}
                    </Button>
                </h2>
                <div dangerouslySetInnerHTML={createMarkupWeb(openStats)} />
            </Modal>
        </div>
    )
}

export default Tickets
