import React, { useState } from 'react'
import { Radio, Button } from 'antd'
import InAppNotifications from './InAppNotifications'
import WhatsAppNotifications from './WhatsAppNotifications'
import EmailNotifications from './EmailNotifications'
import { useNavigate } from 'react-router-dom'

const NOTIFICATION_TYPE_IN_APP = 'NOTIFICATION_TYPE_IN_APP'
const NOTIFICATION_TYPE_WHATSAPP = 'NOTIFICATION_TYPE_WHATSAPP'
const NOTIFICATION_TYPE_EMAIL = 'NOTIFICATION_TYPE_EMAIL'
function Notifications() {
    const navigate = useNavigate()
    const [selectedNotificationType, setSelectedNotificationType] = useState(
        NOTIFICATION_TYPE_IN_APP
    )

    const onChangeLeadType = (event) => {
        setSelectedNotificationType(event.target.value)
    }

    const routeToPage = (route, data = {}) => {
        navigate(route, { state: data })
    }

    const getView = () => {
        switch (selectedNotificationType) {
            case NOTIFICATION_TYPE_IN_APP:
                return <InAppNotifications routeToPage={routeToPage} />
            case NOTIFICATION_TYPE_WHATSAPP:
                return <WhatsAppNotifications />
            case NOTIFICATION_TYPE_EMAIL:
                return <EmailNotifications />
            default:
                return <InAppNotifications />
        }
    }

    const routToSendingSummary = () => {
        navigate('/notifications/summary')
    }

    return (
        <div>
            <div>
                <Radio.Group
                    onChange={onChangeLeadType}
                    defaultValue={selectedNotificationType}
                    style={{ marginBottom: 10 }}
                >
                    <Radio.Button
                        value={NOTIFICATION_TYPE_IN_APP}
                        key={NOTIFICATION_TYPE_IN_APP}
                    >
                        Notifications - In App
                    </Radio.Button>
                    <Radio.Button
                        value={NOTIFICATION_TYPE_WHATSAPP}
                        key={NOTIFICATION_TYPE_WHATSAPP}
                    >
                        WhatsApp
                    </Radio.Button>
                    <Radio.Button
                        value={NOTIFICATION_TYPE_EMAIL}
                        key={NOTIFICATION_TYPE_EMAIL}
                    >
                        Email
                    </Radio.Button>
                </Radio.Group>
                <Button
                    style={{ marginLeft: 40 }}
                    onClick={routToSendingSummary}
                >
                    Sending Summary
                </Button>
            </div>
            {getView()}
        </div>
    )
}

export default Notifications
