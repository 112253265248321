import React, { useState, useEffect } from 'react'
import {
    Input,
    Button,
    Popconfirm,
    message,
    Radio,
    Select,
    Checkbox,
    Modal,
    Upload,
    DatePicker,
    Form,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import RouteData from '../Common/RouteData'
import { goBack } from '../../Util/ILUtil'

var NOTIFICATION_TYPE_ADMINS = 'Admins'
var NOTIFICATION_TYPE_ALL = 'All'
var NOTIFICATION_TYPE_REGISTERED_USERS = 'Course'
var NOTIFICATION_TYPE_STREAM = 'Stream'
var NOTIFICATION_TYPE_FILE_UPLOAD = 'File_Upload'

function SendNotification({ location, navigate }) {
    const { Option } = Select
    const { TextArea } = Input
    const { Dragger } = Upload

    const [title, setTitle] = useState('')
    const [loading, setLoading] = useState(false)
    const [notificationType, setNotificationType] = useState('')
    const [courseNames, setCourseNames] = useState([])
    const [attempts, setAttempts] = useState([])
    const [streamData, setStreamData] = useState([])
    const [levelsData, setLevelsData] = useState([])
    const [selectedStream, setSelectedStream] = useState('')
    const [selectedLevel, setSelectedLevel] = useState('')
    const [fileUploadModalVisible, setFileUploadModalVisible] = useState(false)
    const [fileList, setFileList] = useState([])
    const [streamPaidActiveUsersOnly, setStreamPaidActiveUsersOnly] =
        useState(false)
    const [scheduledTime, setScheduledTime] = useState('')
    const [form] = Form.useForm()

    useEffect(() => {
        const { record } = location.state
        if (record && record.notificationType) {
            setNotificationType(record.notificationType)
            setTitle(record.title)
            onChangeType(record.notificationType)

            setTimeout(() => {
                form.setFieldsValue({
                    title: record.title,
                    body: record.body,
                })
            }, 100)
        }
        // eslint-disable-next-line
    }, [])

    const sendNotification = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var routeObj = {}
                routeObj.key = 'route'
                routeObj.value = values.route

                if (!values.routeData) {
                    values.routeData = []
                }

                values.data = values.routeData
                values.data.push(routeObj)
                values.scheduledTime = scheduledTime

                var url = ''
                if (notificationType === NOTIFICATION_TYPE_ADMINS) {
                    url = GLOBAL.URL.NOTIFICATION_TO_ADMINS
                } else {
                    if (notificationType === NOTIFICATION_TYPE_ALL) {
                        values.notificationType = 'All'
                    } else if (
                        notificationType === NOTIFICATION_TYPE_REGISTERED_USERS
                    ) {
                        values.notificationType = 'Course'
                    } else if (notificationType === NOTIFICATION_TYPE_STREAM) {
                        values.notificationType = 'Stream'
                    }

                    url = GLOBAL.URL.NOTIFICATION.ADD_NOTIFICATIONS
                }

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                RestApi.doPost(url, body)
                    .then((response) => {
                        message.success(response.data)
                        if (notificationType !== NOTIFICATION_TYPE_ADMINS) {
                            goBack(navigate)
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const onChangeNotificationType = (event) => {
        setNotificationType(event.target.value)
        onChangeType(event.target.value)
    }

    const onChangeType = (type) => {
        setScheduledTime('')

        if (
            type === NOTIFICATION_TYPE_REGISTERED_USERS &&
            courseNames.length === 0
        ) {
            RestApi.doGet(GLOBAL.URL.GET_NON_BUNDLE_COURSE_NAMES).then(
                (res) => {
                    setCourseNames(res.data)
                }
            )
        }

        if (
            (type === NOTIFICATION_TYPE_REGISTERED_USERS ||
                type === NOTIFICATION_TYPE_STREAM) &&
            courseNames.length === 0
        ) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS_ADMIN).then((res) => {
                setAttempts(res.data)
            })
        }

        if (type === NOTIFICATION_TYPE_STREAM && streamData.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE_USER).then((res) => {
                setStreamData(JSON.parse(res.data))
            })
        }
    }

    const onChangeStream = (e) => {
        var levels = []
        streamData.forEach((row) => {
            if (row.course === e.target.value) {
                levels = row.levels
            }
        })

        setSelectedStream(e.target.value)
        setLevelsData(levels)
        setSelectedLevel('')
    }

    const onChangeLevel = (e) => {
        setSelectedLevel(e.target.value)
    }

    const showFileUploadModal = () => {
        setFileUploadModalVisible(true)
        setFileList([])
    }

    const hideFileUploadModal = () => {
        setFileUploadModalVisible(false)
    }

    const sendNotificationByFileUpload = () => {
        if (fileList.length === 0) {
            message.error('Please attach the file!')
            return
        }

        setLoading(true)

        let formData = new FormData()
        formData.append('file', fileList[0])

        RestApi.doPost(GLOBAL.URL.NOTIFICATION_BY_FILE_UPLOAD, formData)
            .then((response) => {
                message.success(response.data)
                hideFileUploadModal()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onChangeScheduleDate = (value, dateString) => {
        setScheduledTime(dateString)
    }

    const courseNameOptions = () => {
        var options = []
        courseNames.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const attemptOptions = () => {
        var options = []
        attempts.forEach((attempt) => {
            options.push(
                <Option value={attempt.attempt} key={attempt.attempt}>
                    {attempt.attempt}
                </Option>
            )
        })

        return options
    }

    const streamOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const levelOptions = () => {
        var options = []
        levelsData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const uploadProps = {
        multiple: false,
        onRemove: (file) => {
            const index = fileList.indexOf(file)
            const newFileList = fileList.slice()
            newFileList.splice(index, 1)
            setFileList(newFileList)
        },
        beforeUpload: (file) => {
            setFileList([file])
            return false
        },
        fileList,
    }

    return (
        <div>
            <div>
                <Button
                    type="default"
                    size="small"
                    onClick={() => goBack(navigate)}
                >
                    {'<-'} Back
                </Button>
            </div>
            <h2 style={{ marginTop: 10 }}>Send Notification</h2>
            <Radio.Group
                onChange={onChangeNotificationType}
                value={notificationType}
            >
                <Radio.Button
                    value={NOTIFICATION_TYPE_ADMINS}
                    key={NOTIFICATION_TYPE_ADMINS}
                >
                    {'Admins'}
                </Radio.Button>
                <Radio.Button
                    value={NOTIFICATION_TYPE_ALL}
                    key={NOTIFICATION_TYPE_ALL}
                >
                    {'All'}
                </Radio.Button>
                <Radio.Button
                    value={NOTIFICATION_TYPE_REGISTERED_USERS}
                    key={NOTIFICATION_TYPE_REGISTERED_USERS}
                >
                    {'Course Users'}
                </Radio.Button>
                <Radio.Button
                    value={NOTIFICATION_TYPE_STREAM}
                    key={NOTIFICATION_TYPE_STREAM}
                >
                    {'By Stream'}
                </Radio.Button>
                <Radio.Button
                    value={NOTIFICATION_TYPE_FILE_UPLOAD}
                    key={NOTIFICATION_TYPE_FILE_UPLOAD}
                >
                    {'File Upload'}
                </Radio.Button>
            </Radio.Group>
            {notificationType ? (
                notificationType === NOTIFICATION_TYPE_FILE_UPLOAD ? (
                    <div style={{ marginTop: 10 }}>
                        <Button
                            type="primary"
                            size="small"
                            onClick={showFileUploadModal}
                        >
                            File Upload
                        </Button>
                    </div>
                ) : (
                    <div style={{ width: '50%', marginTop: 10 }}>
                        <Form
                            form={form}
                            initialValues={{ subscribedUsersOnly: false }}
                            layout="horizontal"
                            {...formItemLayout}
                        >
                            <Form.Item
                                label="Title"
                                style={{ marginBottom: 5, lineHeight: 0 }}
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Needed!',
                                    },
                                ]}
                            >
                                <Input
                                    autoFocus
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </Form.Item>
                            <div style={{ marginLeft: 214 }}>
                                Title chars length: {title.length}
                            </div>
                            {title.length > 43 ? (
                                <div
                                    style={{
                                        textAlign: 'right',
                                        color: 'red',
                                    }}
                                >
                                    Recommended title length is 43 characters,
                                    beyond that it may get trimmed
                                </div>
                            ) : null}
                            <Form.Item
                                label="Body"
                                style={{ marginBottom: 5, lineHeight: 0 }}
                                name="body"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter body!',
                                    },
                                ]}
                            >
                                <TextArea rows={3} />
                            </Form.Item>
                            <Form.Item
                                label="Image URL"
                                style={{ marginBottom: 5, lineHeight: 0 }}
                                name="image"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            {notificationType === NOTIFICATION_TYPE_ALL ||
                            notificationType ===
                                NOTIFICATION_TYPE_REGISTERED_USERS ||
                            notificationType === NOTIFICATION_TYPE_STREAM ? (
                                <Form.Item
                                    style={{
                                        marginBottom: 5,
                                        textAlign: 'right',
                                    }}
                                    name="pushNotificationOnly"
                                >
                                    <Checkbox>Push Notification Only?</Checkbox>
                                </Form.Item>
                            ) : null}
                            {notificationType !== NOTIFICATION_TYPE_ADMINS ? (
                                <Form.Item
                                    label="Schedule"
                                    style={{
                                        marginBottom: 5,
                                        lineHeight: 0,
                                    }}
                                    name="scheduledTime"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        showTime
                                        placeholder="Select Date &amp; Time"
                                        format="DD-MMM-YY HH:mm"
                                        onChange={onChangeScheduleDate}
                                    />
                                </Form.Item>
                            ) : null}
                            {notificationType ===
                            NOTIFICATION_TYPE_REGISTERED_USERS ? (
                                <Form.Item
                                    label="Select Courses"
                                    style={{
                                        marginBottom: 5,
                                        lineHeight: 0,
                                    }}
                                    name="courseNames"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Select course(s)!',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select course"
                                        mode="multiple"
                                    >
                                        {courseNameOptions()}
                                    </Select>
                                </Form.Item>
                            ) : null}
                            {notificationType === NOTIFICATION_TYPE_STREAM ? (
                                <Form.Item
                                    label="Select course"
                                    style={{
                                        marginBottom: 5,
                                        lineHeight: 0,
                                    }}
                                    name="course"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select course',
                                        },
                                    ]}
                                >
                                    <Radio.Group onChange={onChangeStream}>
                                        {streamOptions()}
                                    </Radio.Group>
                                </Form.Item>
                            ) : null}
                            {notificationType === NOTIFICATION_TYPE_STREAM &&
                            selectedStream ? (
                                <Form.Item
                                    label="Select level"
                                    style={{
                                        marginBottom: 5,
                                        lineHeight: 0,
                                    }}
                                    name="level"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Radio.Group
                                        onChange={onChangeLevel}
                                        value={selectedLevel}
                                    >
                                        {levelOptions()}
                                    </Radio.Group>
                                </Form.Item>
                            ) : null}
                            {notificationType === NOTIFICATION_TYPE_STREAM &&
                            selectedStream ? (
                                <Form.Item
                                    label="Paid Active Users Only?"
                                    style={{
                                        marginBottom: 5,
                                        lineHeight: 0,
                                    }}
                                    name="subscribedUsersOnly"
                                    valuePropName="checked"
                                >
                                    <Checkbox
                                        onChange={(e) =>
                                            setStreamPaidActiveUsersOnly(
                                                e.target.checked
                                            )
                                        }
                                    ></Checkbox>
                                </Form.Item>
                            ) : null}
                            {notificationType ===
                                NOTIFICATION_TYPE_REGISTERED_USERS ||
                            (notificationType === NOTIFICATION_TYPE_STREAM &&
                                streamPaidActiveUsersOnly) ? (
                                <Form.Item
                                    label="Select Attempts"
                                    style={{
                                        marginBottom: 5,
                                        lineHeight: 0,
                                    }}
                                    name="attemptNames"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Select attempt(s)!',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select attempts"
                                        mode="multiple"
                                    >
                                        {attemptOptions()}
                                    </Select>
                                </Form.Item>
                            ) : null}
                            <RouteData form={form} />
                        </Form>

                        <div style={{ marginTop: 10 }}>
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={sendNotification}
                                type="danger"
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary" loading={loading}>
                                    Send Notification to {notificationType}
                                </Button>
                            </Popconfirm>
                        </div>
                    </div>
                )
            ) : null}
            <Modal
                title="Upload File"
                open={fileUploadModalVisible}
                footer={null}
                onCancel={hideFileUploadModal}
                destroyOnClose={true}
            >
                <h3>Send notification by excel file upload</h3>
                <ul>
                    <li>Have only 1 sheet in the excel file</li>
                    <li>
                        Mandatory column names:{' '}
                        <strong>Email, Title, Body, Image, Link</strong>
                        <br />
                        (Do not have formulae in these columns)
                    </li>
                </ul>
                <Dragger {...uploadProps} style={{ marginTop: 30 }}>
                    <Button>
                        <UploadOutlined /> Click or Drag files to this area to
                        add attachment
                    </Button>
                </Dragger>
                <Button
                    type="primary"
                    style={{ marginTop: 30 }}
                    onClick={sendNotificationByFileUpload}
                    disabled={fileList.length === 0}
                    loading={loading}
                >
                    Send Notification
                </Button>
            </Modal>
        </div>
    )
}

export default SendNotification
