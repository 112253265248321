// eslint-disable-next-line
var PROD_URL = 'https://admin-api.indigolearn.com'
// eslint-disable-next-line
var TEST_URL = 'https://api-test.indigolearn.com'
// eslint-disable-next-line
var LOCAL_URL = 'http://localhost:8888/ROOT'
var BASE_URL = PROD_URL

module.exports = {
    COLORAPP: {
        BLUE: '#00B9F5',
        YELLOWBUTTON: '#eebf26',
        DARKBLUE: '#0F3274',
        LIGHTBLUE: '#6EA8DA',
        THEME_BLUE: '#136094',
        JAMAGREEN: '#33ac99',
        TORQUOISE: '#1abc9c',
        DARKGRAY: '#999',
        WHITE: '#FFE',
        YELLOW: '#fff59d',
        JAMAORANGE: '#f1c40f',
        APPBLUE: '#007aff',
        SUNFLOWER: '#f1c40f',
        EMARALD: '#2ecc71',
        MOONSHADOW: '#8e44ad',
        DEEPLILAC: '#9b59b6',
        AMYTHEIST: '#9b59b6',
        WESTERIA: '#8e44ad',
        TEAL: '#009688',
        SILVER: '#bdc3c7',
        DARKGREY: '#3c3c3c',
        ALIZARIN: '#e74c3c',
        BLACK: '#000',
        CLOUDS: '#eee',
        CONCRETE: '#95a5a6',
        ASBESTOS: '#7f8c8d',
        PETERRIVER: '#3498db',
        DASHBOARD_ICONS: '#5c5c5c',
    },
    HEADER: {
        AUTH: 'x-il-auth',
    },
    TOKEN_KEY: 'token',
    AUTH_TOKEN: null,
    USER_DATA: {},
    //URL
    URL: {
        BASE_URL: BASE_URL,
        // SIGNIN
        TESTSIGNIN_URL: '/signin/testappusersignin/',
        // GoogleSignIn
        GOOGlESIGNINIL_URL: '/signin/googlesignin',
        // Admin Login
        ADMIN_GOOGLE_LOGIN: '/signin/adminappgooglesignin',
        //NEWSANDUPDATES
        GET_NEWS: '/news/admin',
        ADD_NEWS: '/news/admin/add',
        GET_NEWS_BY_ID: '/news/admin/',
        UPDATE_NEWS: '/news/admin/update',
        PUBLISH_NEWS: '/news/admin/publish',
        UNPUBLISH_NEWS: '/news/admin/unpublish',
        GET_NEWS_COMMENTS: '/news/admin/comments?filter=',
        GET_NEWS_WITH_OPEN_COMMENTS_LIST: '/news/admin/unanswered',
        GET_NEWS_WITH_OPEN_COMMENTS: '/news/admin/opencomments/',
        ADD_NEWS_COMMENT: '/news/admin/comments/add',
        DELETE_NEWS_COMMENT: '/news/admin/comment/delete',
        MARK_NEWS_COMMENT_ANSWERED: '/news/admin/comment/answered',
        MARK_NEWS_COMMENT_UNANSWERED: '/news/admin/comment/unanswered',
        ENABLE_COMMENTS_IN_NEWS: '/news/admin/comments/enable',
        DISABLE_COMMENTS_IN_NEWS: '/news/admin/comments/disable',
        MARK_ALL_COMMENTS_IN_NEWS_ANSWERED:
            '/news/admin/comments/markallsanswered',
        DELETE_NEWS: '/news/admin/delete',
        //MarketPlace
        //attempts
        GET_ALL_ATTEMPTS: '/mp/admin/attempts',
        GET_ALL_ATTEMPTS_ADMIN: '/mp/admin/attempts/all',
        GET_ATTEMPTS_ADMIN: '/mp/admin/attempts/get',
        ADD_ATTEMPT: '/mp/admin/attempt/add',
        UPDATE_ATTEMPT: '/mp/admin/attempt/update',
        ENABLE_ATTEMPT: '/mp/admin/attempt/enable',
        DISABLE_ATTEMPT: '/mp/admin/attempt/disable',
        // Faculty Weightage
        GET_FACULTY_COURSE_WEIGHTAGE_DATE_RANGE:
            '/mp/admin/course/faculty/daterange/get?courseId=',
        ADD_FACULTY_COURSE_WEIGHTAGE_DATE_RANGE:
            '/mp/admin/course/faculty/daterange/add',
        UPDATE_FACULTY_COURSE_WEIGHTAGE_DATE_RANGE:
            '/mp/admin/course/faculty/daterange/update',
        DELETE_FACULTY_COURSE_WEIGHTAGE_DATE_RANGE:
            '/mp/admin/course/faculty/daterange/delete',
        GET_FACULTY_COURSE_WEIGHTAGE:
            '/mp/admin/course/faculty/weightage/get?dateRangeId=',
        ADD_FACULTY_COURSE_WEIGHTAGE: '/mp/admin/course/faculty/weightage/add',
        UPDATE_FACULTY_COURSE_WEIGHTAGE:
            '/mp/admin/course/faculty/weightage/update',
        DELETE_FACULTY_COURSE_WEIGHTAGE:
            '/mp/admin/course/faculty/weightage/delete',

        GET_CATLOG: '/mp/admin/catalog',
        GET_COURSE_GROUP: '/mp/admin/group/',
        GET_COURSE: '/mp/admin/course/',
        GET_COURSE_BY_ID: '/mp/admin/course/id/',

        GET_ALL_COURSES: '/mp/admin/courses',
        GET_ALL_COURSES_LIST_WITH_IDS: '/mp/admin/courses/list/withids',
        GET_ALL_NON_BUNDLE_FULL_COURSE_LIST_WITH_IDS:
            '/mp/admin/courses/nonbundle/fullcourseslist/withids',
        ADD_COURSE_GROUP: '/mp/admin/group/add',
        ADD_COURSE: '/mp/admin/course/add',
        COPY_COURSE: '/mp/admin/course/copy',
        UPDATE_COURSE_BASIC_DETAILS: '/mp/admin/course/details/basic/update',
        UPDATE_COURSE_BUNDLE_INFO: '/mp/admin/course/bundleinfo/update',
        GET_COURSES_IN_BUNDLE:
            '/mp/admin/course/bundle/course/get?bundleCourseId=',
        ADD_COURSE_TO_BUNDLE: '/mp/admin/course/bundle/course/add',
        ENABLE_COURSE_IN_BUNDLE: '/mp/admin/course/bundle/course/enable',
        DISABLE_COURSE_IN_BUNDLE: '/mp/admin/course/bundle/course/disable',
        REMOVE_COURSE_FROM_BUNDLE: '/mp/admin/course/bundle/course/remove',
        REORDER_COURSES_IN_BUNDLE: '/mp/admin/course/bundle/course/reorder',
        UPDATE_COURSE_FACULTY: '/mp/admin/course/faculty/update',
        UPDATE_COURSE_DESCRIPTION_APP:
            '/mp/admin/course/description/app/update',
        UPDATE_COURSE_DESCRIPTION: '/mp/admin/course/details/update',
        ENABLE_COURSE_PRICE: '/mp/admin/course/price/enable',
        DISABLE_COURSE_PRICE: '/mp/admin/course/price/disable',
        DELETE_COURSE_PRICE: '/mp/admin/course/price/delete',
        ADD_COURSE_PRICE: '/mp/admin/course/price/add',
        UPDATE_COURSE_PRICE: '/mp/admin/course/price/update',
        UPDATE_COURSE_PRICES_BULK: '/mp/admin/course/price/update_bulk',
        REORDER_COURSE_PRICES: '/mp/admin/course/price/reorder',
        RESET_COURSE_CACHE: '/mp/admin/course/reset_cache',

        UPDATE_COURSE_GROUP: '/mp/admin/group/update',
        UPDATE_COURSE: '/mp/admin/course/update',
        COURSE: {
            // Course
            DELETE_COURSE: '/mp/admin/course/delete',
            ARCHIVE_COURSE: '/mp/admin/course/archive',
            UNARCHIVE_COURSE: '/mp/admin/course/unarchive',
            SEARCH_CATALOG_COURSES: '/mp/admin/catalog/course/search',
            GET_ENABLED_PRICES: '/mp/admin/course/price/get/enabled?courseId=',
            // Add Ons
            GET_COURSE_ADD_ONS: '/mp/admin/course/add_on/get?courseId=',
            ADD_COURSE_ADD_ON: '/mp/admin/course/add_on/add',
            DELETE_COURSE_ADD_ON: '/mp/admin/course/add_on/delete',
            REORDER_COURSE_ADD_ON: '/mp/admin/course/add_on/reorder',
            ENABLE_COURSE_ADD_ON: '/mp/admin/course/add_on/enable',
            DISABLE_COURSE_ADD_ON: '/mp/admin/course/add_on/disable',
        },

        GET_STREAM_COURSE: '/stream/admin/courses',
        GET_STREAM_COURSE_USER: '/stream/courses',
        GET_STREAM_NAMES_LIST: '/stream/admin/course_names_list',
        GET_COURSE_GROUP_LIST: '/mp/admin/groups',

        GET_COURSE_NAMES: '/mp/admin/courses/list',
        GET_COURSE_NAMES_WHICH_HAS_PRICING:
            '/mp/admin/courses/name_which_has_pricing',
        GET_NON_BUNDLE_COURSE_NAMES: '/mp/admin/courses/nonbundle/list',
        GET_COURSE_ACCESS_COURSE_NAMES: '/mp/admin/course_access_course_names',
        GET_COURSE_ACCESS_COURSE_NAMES_WITH_IDS:
            '/mp/admin/course_access_course_names_ids',
        GET_GROUP_NAMES: '/mp/admin/groups/list',

        ADD_GROUP_TO_CATLOG: '/mp/admin/catalog/group/add',
        ADD_COURSE_TO_CATLOG: '/mp/admin/catalog/course/add',

        ENABLE_CATLOG_ITEM: '/mp/admin/catalog/enable',
        DISABLE_CATLOG_ITEM: '/mp/admin/catalog/disable',
        UNLINK_ITEM: '/mp/admin/catalog/unlink',

        REORDER_CATALOG_ITEMS: '/mp/admin/reorder',

        ENABLE_ALL_COURSE_CATALOG_ITEMS: '/mp/admin/catalog/enable/all',
        DISABLE_ALL_COURSE_CATALOG_ITEMS: '/mp/admin/catalog/disable/all',
        UPDATE_COURSE_CATALOG_SUB_GROUP_NAME:
            '/mp/admin/catalog/subgroupname/update',
        UPDATE_COURSE_CATALOG_GROUP_BUTTON_CUSTOM_NAME:
            '/mp/admin/catalog/group_button_custom_name/update',
        //Ecommerce
        //Coupons
        GET_ALL_COUPONS: '/coupon/admin/all',
        GET_COUPON_TYPES: '/coupon/admin/types',
        ADD_COUPON: '/coupon/admin/add',
        UPDATE_COUPON: '/coupon/admin/update',
        UPDATE_COUPON_DATE_RANGE: '/coupon/admin/update/date_range',
        UPDATE_SALES_USER_IN_COUPON: '/coupon/admin/update/sales_user',
        CLEAR_SALES_USER_IN_COUPON: '/coupon/admin/update/sales_user/clear',
        DELETE_COUPON: '/coupon/admin/delete',
        ENABLE_COUPON: '/coupon/admin/enable',
        DISABLE_COUPON: '/coupon/admin/disable',
        GET_COUPON_COURSE_MAPPING: '/coupon/admin/coursemappings/get/',
        UPDATE_COUPON_COURSE_MAPPING: '/coupon/admin/coursemappings/update',
        ARCHIVE_COUPON: '/coupon/admin/archive',
        UNARCHIVE_COUPON: '/coupon/admin/unarchive',
        GET_ARCHIVED_COUPONS: '/coupon/admin/archived',
        GET_BULK_COUPONS: '/coupon/admin/bulk/get',
        SEARCH_COUPON: '/coupon/admin/search?query=',
        CREATE_BULK_COUPONS: '/coupon/admin/bulk/create',
        CREATE_PARTNER_COUPONS: '/coupon/admin/create_partner_coupons',
        GET_COUPON_USER_MAPPING: '/coupon/admin/usermapping/get',
        ADD_USER_TO_COUPON: '/coupon/admin/usermapping/add',
        REMOVE_USER_FROM_COUPON: '/coupon/admin/usermapping/delete',
        CLEAR_USERS_FROM_COUPON: '/coupon/admin/usermapping/clear',
        INCREASE_COUPON_LIMIT: '/coupon/admin/increase_max_uses',
        DECREASE_COUPON_LIMIT: '/coupon/admin/decrease_max_uses',
        CREATE_SPECIAL_COUPONS: '/coupon/admin/generate_special_coupons',
        DELETE_ALL_SPECIAL_COUPONS: '/coupon/admin/delete_all_special_coupons',
        // Offer Coupon
        GET_OFFER_COUPON: '/coupon/admin/offer/get',
        UPDATE_OFFER_COUPON: '/coupon/admin/offer/update',
        // Sales User Linked
        POPULATE_LIMIT_BY_SALES: '/coupon/admin/populate_limit_by_sales',
        REMOVE_LIMIT_BY_SALES: '/coupon/admin/remove_limit_by_sales',
        // Partner
        GET_PARTNERS: '/coupon/admin/partner/get',
        ADD_PARTNER: '/coupon/admin/partner/add',
        UPDATE_PARTNER: '/coupon/admin/partner/update',
        DELETE_PARTNER: '/coupon/admin/partner/delete',
        ARCHIVE_PARTNER: '/coupon/admin/partner/archive',
        UNARCHIVE_PARTNER: '/coupon/admin/partner/unarchive',
        // Partner Invoices:
        GET_PARTNER_INVOICES: '/invoice/admin/partner/get',
        MARK_PARTNER_INVOICE_PAID: '/invoice/admin/partner/paid',
        MARK_PARTNER_INVOICE_UNPAID: '/invoice/admin/partner/unpaid',
        SENT_PARTNER_INVOICE: '/invoice/admin/partner/send',
        DELETE_PARTNER_INVOICE: '/invoice/admin/partner/delete',
        //Free Resources
        //folders
        GET_FOLDERS: '/resources/admin/folders',
        ADD_FOLDERS: '/resources/admin/folder/add',
        UPDATE_FOLDERS: '/resources/admin/folder/update',
        DELETE_FOLDER: '/resources/admin/folder/delete',
        //Files
        SEARCH_FILES: '/resources/admin/file/search?query=',
        ADD_FILES: '/resources/admin/file/add',
        UPDATE_FILE_NAME: '/resources/admin/file/name/update',
        UPDATE_FILE_CONTENT: '/resources/admin/file/content/update',
        UPDATE_FILE_DATA: '/resources/admin/file/data/update',
        DELETE_FILE: '/resources/admin/file/delete',
        GET_FILE_TYPES: '/resources/admin/filetype',
        //FreeResourcesCatlog
        GET_ROOT_CATLOG: '/resources/admin/catalog',
        ADD_TO_CATLOG: '/resources/admin/catalog/add',
        DELETE_FROM_CATLOG: '/resources/admin/catalog/delete',
        ENABLE_FREE_RESOURSE: '/resources/admin/catalog/enable',
        DISABLE_FREE_RESOURCE: '/resources/admin/catalog/disable',
        GET_FOLDERS_LIKE: '/resources/admin/catalog/folder/like?query=',
        ADD_FOLDER_TO_RESOURCES_CATALOG: '/resources/admin/catalog/add/folder',
        GET_FILES_LIKE: '/resources/admin/catalog/file/like?query=',
        ADD_FILE_TO_RESOURCES_CATALOG: '/resources/admin/catalog/add/file',
        REORDER_FREE_RESOURCES: '/resources/admin/catalog/reorder',
        UPDATE_FILE_OR_FOLDER_NAME_FREE_RESOURCE:
            '/resources/admin/catalog/name/update',
        //MCQ
        //SUBJECTS
        GET_SUBJECTS_BY_STREAM: '/mcq/admin/subjects',
        GET_ALL_SUBJECTS: '/mcq/admin/subjects/all',
        ADD_SUBJECT: '/mcq/admin/subject/add',
        UPDATE_SUBJECT: '/mcq/admin/subject/update',
        DELETE_SUBJECT: '/mcq/admin/subject/delete',
        REORDER_SUBJECT: '/mcq/admin/subject/reorder',
        ENABLE_SUBJECT: '/mcq/admin/subject/enable',
        DISABLE_SUBJECT: '/mcq/admin/subject/disable',
        //Chapter
        GET_CHAPTERS_BY_SUBJECT: '/mcq/admin/chapters',
        ADD_CHAPTER: '/mcq/admin/chapter/add',
        UPDATE_CHAPTER: '/mcq/admin/chapter/update',
        DELETE_CHAPTER: '/mcq/admin/chapter/delete',
        REORDER_CHAPTERS: '/mcq/admin/chapter/reorder',
        //Section
        GET_SECTIONS: '/mcq/admin/sections',
        ADD_SECTION: '/mcq/admin/section/add',
        UPDATE_SECTION: '/mcq/admin/section/update',
        DELETE_SECTION: '/mcq/admin/section/delete',
        REORDER_SECTIONS: '/mcq/admin/section/reorder',
        //Topics
        GET_TOPICS: '/mcq/admin/topics',
        ADD_TOPIC: '/mcq/admin/topic/add',
        UPDATE_TOPIC: '/mcq/admin/topic/update',
        DELETE_TOPIC: '/mcq/admin/topic/delete',
        //Difficulty
        GET_DIFFICULTY_LEVELS: '/mcq/admin/difficulty/levels',
        ADD_LEVEL: '/mcq/admin/difficulty/level/add',
        UPDATE_LEVEL: '/mcq/admin/difficulty/level/update',
        DELETE_LEVEL: '/mcq/admin/difficulty/level/delete',
        //Question
        ADD_QUESTION: '/mcq/admin/question/add',
        UPDATE_QUESTION: '/mcq/admin/question/update',
        GET_QUESTIONS_IN_TEST: '/mcq/admin/test/questions?testId=',
        GET_QUESTION_DETAILS_BY_TEST_ID: '/mcq/admin/question/test/details/',
        GET_QUESTION_DETAILS: '/mcq/admin/question/',
        DELETE_QUESTIONS: '/mcq/admin/questions/delete',
        SEARCH_MCQ: '/mcq/admin/question/search',
        DELETE_QUESTION: '/mcq/admin/question/delete',
        UPLOAD_QUESTIONS: '/mcq/admin/questions/upload',
        REPLACE_QUESTIONS: '/mcq/admin/questions/replace',
        //Tests
        GET_TESTS: '/mcq/admin/tests?subject=',
        GET_TEST: '/mcq/admin/test/get?testId=',
        DELETE_TEST: '/mcq/admin/test/delete',
        ENABLE_TEST: '/mcq/admin/test/enable',
        DISABLE_TEST: '/mcq/admin/test/disable',
        ADD_TEST: '/mcq/admin/test/add',
        UPDATE_TEST: '/mcq/admin/test/update',
        REORDER_TESTS: '/mcq/admin/test/reorder',
        // Course Test
        SEARCH_COURSE_TEST: '/mcq/admin/test/course/search?query=',
        ADD_COURSE_TEST: '/mcq/admin/test/course/add',
        UPDATE_COURSE_TEST: '/mcq/admin/test/course/update',
        DELETE_COURSE_TEST: '/mcq/admin/test/course/delete',
        ADD_COURSE_TEST_QUESTION: '/mcq/admin/question/course/add',
        UPDATE_COURSE_TEST_QUESTION: '/mcq/admin/question/course/update',
        // Random Test Config
        GET_RANDOM_TEST_CONFIG:
            '/mcq/admin/test/random_test/config/get?testId=',
        ADD_RANDOM_TEST_CONFIG: '/mcq/admin/test/random_test/config/add',
        UPDATE_RANDOM_TEST_CONFIG: '/mcq/admin/test/random_test/config/update',
        DELETE_RANDOM_TEST_CONFIG: '/mcq/admin/test/random_test/config/delete',
        //Pages
        ADD_PAGE: '/page/admin/add',
        GET_PAGE: '/page/admin/get/',
        GET_LIST_OF_PAGES: '/page/admin/get/all',
        GET_STATS_TYPE: '/home/admin/availablestats',
        UPDATE_PAGE: '/page/admin/update',
        DELETE_PAGE: '/page/admin/delete',
        ENABLE_PAGE: '/page/admin/enable',
        DISABLE_PAGE: '/page/admin/disable',
        UPDATE_PAGE_TITLE_LINK: '/page/admin/titlelink/update',
        //Home
        GET_LIST_OF_TYPES: '/home/admin/types',
        GET_ITEMS_BY_TYPES: '/home/admin/get/all?type=',
        ADD_HOME_ITEM: '/home/admin/add',
        UPDATE_HOME_ITEM: '/home/admin/update',
        DELETE_HOME_ITEM: '/home/admin/delete',
        ENABLE_HOME_ITEM: '/home/admin/enable',
        DISABLE_HOME_ITEM: '/home/admin/disable',
        ENABLE_HOME_ITEM_WEB: '/home/admin/web/enable',
        DISABLE_HOME_ITEM_WEB: '/home/admin/web/disable',
        ENABLE_HOME_ITEM_COURSE_DETAILS: '/home/admin/coursedetails/enable',
        DISABLE_HOME_ITEM_COURSE_DETAILS: '/home/admin/coursedetails/disable',
        ENABLE_HOME_ITEM_FEED: '/home/admin/feed/enable',
        DISABLE_HOME_ITEM_FEED: '/home/admin/feed/disable',
        REORDER_HOME_ITEMS: '/home/admin/rearrange',
        ENABLE_ALL_HOME_ITEMS: '/home/admin/enable_all',
        DISABLE_ALL_HOME_ITEMS: '/home/admin/disable_all',
        GET_HOME_TOP_BANNER: '/home/admin/top_banner/get',
        UPDATE_HOME_TOP_BANNER: '/home/admin/top_banner/update',
        //TRANSACTIONS
        GET_TRANSACTIONS: '/transaction/admin/get/all?status=',
        DOWNLOAD_TRANSACTIONS: '/transaction/admin/download',
        GET_TRANSACTION_DETAILS: '/transaction/admin/get/',
        MANUAL_VERIFY_TRANSACTION: '/transaction/admin/manualverify',
        AUTO_VERIFY_TRANSACTION: '/transaction/admin/autoverify',
        REFUND_TRANSACTION: '/transaction/admin/refund',
        UPDATE_TRANSACTION: '/transaction/admin/update',
        DELETE_TRANSACTION: '/transaction/admin/delete',
        ADD_COMMENT_IN_TRANSACTION: '/transaction/admin/comment/add',
        LOAN_APPROVED: '/transaction/admin/loan/approve',
        LOAN_REJECTED: '/transaction/admin/loan/reject',
        GET_TRANSACTION_TYPES: '/transaction/admin/types',
        GET_PAYMENT_GATEWAYS: '/transaction/admin/paymentgateways',
        GENERATE_INVOICE_FOR_TRANSACTION: '/transaction/admin/invoice/generate',
        UPDATE_ATTEMPT_IN_TRANSACTION_ITEM:
            '/transaction/admin/item/attempt/update',
        UPDATE_TRANSACTIONS_BY_COURSE_AND_ATTEMPT:
            '/transaction/admin/update_by_course_attempt',
        MIGRATE_TRANSACTION_TO_COURSE_ACCESS:
            '/transaction/admin/migrate/courseaccess/transaction',
        MIGRATE_TRANSACTION_TO_COURIER: '/transaction/admin/migrate/courier',
        //CART LEADS
        GET_CART_LEADS: '/cart/admin/leads/get',
        GET_CART_LEAD: '/cart/admin/lead/get/',
        CLOSE_CART_LEAD: '/cart/admin/lead/close',
        OPEN_CART_LEAD: '/cart/admin/lead/open',
        ADD_COMMENTS_TO_CART_LEAD: '/cart/admin/lead/comments/add',
        PIN_CART_LEAD_COMMENT: '/cart/admin/lead/comments/pin',
        UNPIN_CART_LEAD_COMMENT: '/cart/admin/lead/comments/unpin',
        GET_CART_LEAD_LATEST_COMMENTS: '/cart/admin/lead/comments/latest',
        GET_CART_LEAD_ALL_COMMENTS: '/cart/admin/lead/comments/get',
        UPDATE_CART_LEAD_COMMENTS_DATE: '/cart/admin/lead/comments/update_date',
        UPDATE_CART_LEAD_COMMENTS_DURATION:
            '/cart/admin/lead/comments/update_duration',
        DELETE_CART_LEAD_COMMENTS: '/cart/admin/lead/comments/delete',
        UPDATE_CART_LEAD_COMMENTS: '/cart/admin/lead/comments/update',
        SET_CART_LEAD_REMINDER_DATE: '/cart/admin/lead/reminder/set',
        GET_CART_LEAD_ADMIN_USERS: '/cart/admin/users',
        ASSIGN_CART_LEAD: '/cart/admin/lead/assign',
        BULK_ASSIGN_CART_LEAD: '/cart/admin/lead/assign_bulk',
        ADD_CALL_MADE_ENTRY_TO_CART_LEAD: '/cart/admin/lead/add_call_entry',
        GET_CART_LEAD_TYPES: '/cart/admin/lead/type/get',
        GET_ENABLED_CART_LEAD_TYPES: '/cart/admin/lead/type_enabled/get',
        ADD_CART_LEAD_TYPE: '/cart/admin/lead/type/add',
        UPDATE_CART_LEAD_TYPE: '/cart/admin/lead/type/update',
        ENABLE_CART_LEAD_TYPE: '/cart/admin/lead/type/enable',
        DISABLE_CART_LEAD_TYPE: '/cart/admin/lead/type/disable',
        DELETE_CART_LEAD_TYPE: '/cart/admin/lead/type/delete',
        GET_CART_LEAD_STATUS_TYPES: '/cart/admin/lead/types/status',
        GET_CART_LEAD_TYPE_GROUP_NAMES: '/cart/admin/lead/type/group_names',
        GET_CART_LEAD_OPEN_STATUS_TYPES: '/cart/admin/lead/types/open',
        GET_CART_LEAD_CLOSED_STATUS_TYPES: '/cart/admin/lead/types/closed',
        UPDATE_CART_LEAD_OPEN_TYPE: '/cart/admin/lead/type/open/update',
        GET_CART_LEADS_DASHBOARD: '/cart/admin/leads/dashboard',
        GET_LEADS_DASHBOARD_BY_STREAM: '/leads/admin/dashboard_by_stream',
        GET_LEADS_DASHBOARD_BY_STREAM_AND_LEAD_TYPE:
            '/leads/admin/dashboard_by_stream_and_lead_type',
        UPDATE_CART_LEAD: '/cart/admin/lead/update',
        LEADS: {
            GET_ALL_OPEN_ACTIONABLE_LEADS:
                '/leads/admin/actionable_leads_count',
            // External Lead
            GET_LEADS_EXTERNAL: '/lead_external/admin/get',
            GET_LEAD_EXTERNAL_BY_LEAD_ID:
                '/lead_external/admin/get_lead?leadId=',
            ADD_LEAD_EXTERNAL: '/lead_external/admin/add',
            UPDATE_LEAD_EXTERNAL: '/lead_external/admin/update',
            DELETE_LEAD_EXTERNAL: '/lead_external/admin/delete',
            GET_LEAD_EXTERNAL_SOURCES: '/lead_external/admin/sources',
            ASSIGN_LEADS_EXTERNAL_BULK: '/lead_external/admin/assign/bulk',
            ADD_CALL_ENTRY_TO_EXTERNAL_LEAD:
                '/lead_external/admin/add_call_entry',
            GET_LEAD_EXTERNAL_ACQUISITION_INFO:
                '/lead_external/admin/acquisition_info?leadId=',
            // External Lead Comments
            GET_LEAD_EXTERNAL_COMMENTS:
                '/lead_external/admin/comment/get?leadId=',
            ADD_LEAD_EXTERNAL_COMMENT: '/lead_external/admin/comment/add',
            UPDATE_LEAD_EXTERNAL_COMMENT: '/lead_external/admin/comment/update',
            DELETE_LEAD_EXTERNAL_COMMENT: '/lead_external/admin/comment/delete',
            GET_LEAD_EXTERNAL_COMMENT_LATEST:
                '/lead_external/admin/comment/latest',
            PIN_LEAD_EXTERNAL_COMMENT: '/lead_external/admin/comment/pin',
            UNPIN_LEAD_EXTERNAL_COMMENT: '/lead_external/admin/comment/unpin',
            // External Lead Courses
            GET_LEAD_EXTERNAL_COURSES:
                '/lead_external/admin/course/get?leadId=',
            ADD_LEAD_EXTERNAL_COURSES: '/lead_external/admin/course/add',
            DELETE_LEAD_EXTERNAL_COURSE: '/lead_external/admin/course/delete',
            // Internal leads:
            GET_LEAD_SOURCES: '/cart/admin/lead/sources',
            // Sign up leads
            GET_SIGN_UP_LEADS: '/leads/admin/signup',
            // Lead Sub Types
            GET_LEAD_SUB_TYPES: '/cart/admin/lead/sub_type/get?parentId=',
            ADD_LEAD_SUB_TYPE: '/cart/admin/lead/sub_type/add',
            UPDATE_LEAD_SUB_TYPE: '/cart/admin/lead/sub_type/update',
            GET_LEAD_SUB_TYPES_ENABLED:
                '/cart/admin/lead/sub_types/enabled/get?',
            // Leads
            GET_LEADS_CURRENT_STATUS: '/leads/admin/leads_current_status',
            GET_LEADS_FILTERS_DATA: '/leads/admin/filters_data',
            GET_FOLLOW_UP_LEADS_COUNT_SPLIT_BY_DAY:
                '/leads/admin/follow_up_leads_count_by_day',
            // UTM
            GET_UTM_SOURCES: '/leads/admin/utm_sources',
            GET_UTM_MEDIUMS: '/leads/admin/utm_mediums',
            GET_UTM_CAMPAIGNS: '/leads/admin/utm_campaigns',
            GET_UTM_TERMS: '/leads/admin/utm_terms',
            DOWNLOAD_UTM_LEADS_REPORT: '/leads/admin/utm_leads_report',
            DOWNLOAD_UTM_LEADS_REPORT_BY_TRANSACTIONS:
                '/leads/admin/utm_leads_report_by_transactions',
            // High Prospect
            UPDATE_HIGH_PROSPECT_LEAD: '/leads/admin/high_prospect/update',
            GET_HIGH_PROSPECT_LEADS: '/leads/admin/high_prospect/get/all',
            GET_HIGH_PROSPECT_LEADS_EXPECTED_SALES:
                '/leads/admin/high_prospect/expected_sales',
            EXPORT_LEADS: '/leads/admin/export',
            EXPORT_OPEN_LEADS: '/leads/admin/export_open_leads',
        },
        //FLAG
        GET_FLAG_ITEMS: '/flag/admin/get?status=',
        GET_FLAG_CATEGORIES: '/flag/admin/categories/get',
        ADD_FLAG_COMMENT: '/flag/admin/comment/add',
        CLOSE_FLAG: '/flag/admin/close',
        OPEN_FLAG: '/flag/admin/open',
        GET_FLAG_COMMENTS: '/flag/admin/comments?autoId=',
        GET_FLAG_LATEST_COMMENT: '/flag/admin/comment/latest?autoId=',
        GET_FLAG_ADMIN_USERS: '/flag/admin/users',
        ASSIGN_FLAG_ITEM: '/flag/admin/assign',
        GET_FLAGGED_SUBJECTS: '/flag/admin/mcq_subjects?status=',
        UPDATE_QUESTION_ONLY: '/mcq/admin/question/update/question_only',
        UPDATE_OPTION: '/mcq/admin/question/update/option',
        UPDATE_FEEDBACK: '/mcq/admin/question/update/feedback',
        MARK_OPTION_AS_CORRECT_ANSWER:
            '/mcq/admin/question/update/mark_option_as_correct_answer',
        //EMAIL
        SEND_EMAIL_USER: '/email/admin/send_to_user',
        // STATS
        GET_USERS_COUNT_BY_MONTH: '/stats/admin/users',
        GET_ACTIVE_USERS_COUNT_BY_MONTH: '/stats/admin/activeusers',
        GET_USERS_BY_STREAM_SPLIT_BY_MONTH:
            '/stats/admin/users/streamsplit?limit=',
        GET_ACTIVE_USERS_BY_COURSE: '/stats/admin/active_user_by_course',
        GET_USERS_BY_STREAM_SPLIT_TOTAL: '/stats/admin/users/streamsplittotal',
        GET_USERS_BY_STREAM_SPLIT_WEEK_WISE:
            '/stats/admin/users/stream_split_week_wise',
        GET_REVENUE_COUNT_BY_MONTH: '/stats/admin/revenue',
        GET_VIDEO_REQUESTS_COUNT_BY_MONTH: '/stats/admin/videorequests',
        GET_REVENUE_SPLIT_BY_COURSE: '/stats/admin/revenuesplit/course',
        GET_REVENUE_SPLIT_BY_LEVEL: '/stats/admin/revenuesplit/level',
        GET_REVENUE_SPLIT_BY_SUBJECT: '/stats/admin/revenuesplit/subject',
        GET_REVENUE_SPLIT_BY_STATE: '/stats/admin/revenuesplitbystate',
        GET_DSR: '/stats/admin/dsr',
        GET_ARPU: '/stats/admin/arpu',
        GET_REVENUE_SPLIT: '/stats/admin/revenuesplit',
        GET_REVENUE_SPLIT_BY_LAST_LOGIN_AGING:
            '/stats/admin/revenuesplit_by_last_login_aging',
        GET_STREAM_REVENUE: '/stats/admin/streamrevenue',
        GET_ACTIVE_SUBSCRIBERS_MONTH_WISE:
            '/stats/admin/activesubscribers/monthwise',
        GET_ACTIVE_SUBSCRIBERS_DAY_WISE:
            '/stats/admin/activesubscribers/daywise',
        GET_TOTAL_WATCHED_MONTH_WISE: '/stats/admin/totalwatched/monthwise',
        GET_TOTAL_WATCHED_DAY_WISE: '/stats/admin/totalwatched/daywise',
        GET_TOTAL_WATCHED_COURSE_WISE: '/stats/admin/totalwatched/course_wise',
        GET_TOTAL_WATCHED_COURSE_MODULE_WISE:
            '/stats/admin/totalwatched/course_module_wise',
        GET_UNATTENDED_NEW_USERS_BY_MONTH:
            '/stats/admin/unattended_new_users_by_month?limit=',
        GET_UNATTENDED_NEW_USERS_BY_DAY:
            '/stats/admin/unattended_new_users_by_day?month=',
        // USER
        SEARCH_USER: '/user/admin/search',
        GET_USER_BY_ID: '/user/admin/get?userId=',
        GET_USER_INFO: '/userinfo',
        SEND_EMAIL_TO_ALL_USERS: '/user/admin/email/sendtoall',
        GET_USER_TRANSACTIONS: '/user/admin/transactions/',
        GET_USER_BOOK_TRANSACTIONS: '/user/admin/book_transactions?userId=',
        GET_USER_BOOK_TRANSACTION_ITEMS:
            '/user/admin/book_transaction_items?transactionId=',
        GET_USER_COURIERS: '/user/admin/couriers?userId=',
        GET_UESR_COURIER_ITEMS: '/user/admin/courier_items?uid=',
        GET_USER_COURSE_ACCESS_LIST: '/user/admin/courseaccess/',
        EXTEND_COURSES: '/user/admin/courseaccess/extend',
        RESET_COURSE_PRGORESS: '/user/admin/reset_course_progress',
        CLEAR_END_DATE_IN_USER_COURSE: '/user/admin/courseaccess/clearenddate',
        ADD_COURSE_ACCESS: '/user/admin/courseaccess/add',
        DELETE_COURSE_ACCESS: '/user/admin/courseaccess/delete',
        UPDATE_COURSE_ACCESS_DURATION_VIEWS_LIMIT:
            '/user/admin/courseaccess/update_duration_views_limit',
        GET_USER_COURSES: '/user/admin/courses/',
        GET_USER_COURSE_MODULES: '/user/admin/course/modules',
        GET_USER_COURSE_SECTIONS: '/user/admin/course/sections',
        UPDATE_USER_COURSE_ATTEMPT: '/user/admin/coures/update_attempt',
        GET_USER_CART: '/user/admin/cart?userId=',
        GET_USER_CART_VIEW: '/user/admin/user_cart_view?userId=',
        ADD_COURSE_TO_USER_CART: '/user/admin/cart/add',
        DELETE_COURSE_FROM_USER_CART: '/user/admin/cart/delete',
        APPLY_COUPON_TO_USER_CART: '/user/admin/cart/apply_coupon',
        GET_UESR_ALERTS_SUBSCRIPTION:
            '/user/admin/notifications/subscription/get?userId=',
        UPDATE_UESR_ALERTS_SUBSCRIPTION:
            '/user/admin/notifications/subscription/update',
        USER_SUBSCRIPTION_DISABLE_ALL:
            '/user/admin/notifications/subscription/disable_all',
        USER_SUBSCRIPTION_ENABLE_ALL:
            '/user/admin/notifications/subscription/enable_all',
        GET_USER_CREDITS: '/user/admin/credits/',
        GET_USER_CREDITS_HISTORY: '/user/admin/credits/history/',
        GET_USER_POINTS: '/user/admin/points/',
        GET_USER_POINTS_HISTORY: '/user/admin/points/history/',
        GET_USER_DEVICE_CHANGES_COUNT: '/user/admin/devicechangescount/',
        GET_TOTAL_USERS_COUNT: '/user/admin/count',
        GET_USER_DEVICE_CHANGE_HISTORY: '/user/admin/deviceswitchinghistory/',
        INCREASE_DEVICE_SWITCH_HISTORY:
            '/user/admin/deviceChangeThreshold/increase',
        ENABLE_USER: '/user/admin/enable',
        DISABLE_USER: '/user/admin/disable',
        DISABLE_DEVICE_COURSE_ACCESS_CHECK:
            '/user/admin/enable_device_course_access_check',
        ENABLE_DEVICE_COURSE_ACCESS_CHECK:
            '/user/admin/disable_device_course_access_check',
        DELETE_USER: '/user/admin/delete',
        GET_USER_LOGIN_HISTORY: '/user/admin/login_history?userId=',
        GET_USER_ROLES: '/user/admin/getroles?id=',
        GET_USERS_BY_ROLE: '/user/admin/users/role?filter=',
        GET_ROLE_TYPES: '/user/admin/getroletypes',
        GET_USERS_WITH_ADMIN_SITE_ACCESS:
            '/user/admin/users_with_admin_site_access',
        UPDATE_USER_ROLES: '/user/admin/updateroles',
        AWARD_CREDITS_TO_USER: '/credits/admin/add',
        REDEEM_CREDITS_FROM_USER: '/credits/admin/redeem',
        GET_FORUM_TOPICS_USER: '/user/admin/forum/topics',
        GET_FORUM_REPLIES_USER: '/user/admin/forum/replies',
        GET_UNIQUE_ATTEMPT_NAMES_FROM_USER_PROFILE: '/user/admin/attempts',
        UPDATE_USER_PHONE: '/user/admin/phone/update',
        UPDATE_USER_EMAIL: '/user/admin/update/email',
        UPDATE_OFFLINE_DOWNLOAD_LIMIT: '/user/admin/downloadlimit/update',
        GET_USER_PLACE_HOLDERS: '/user/admin/place_holders',
        GET_USER_EXTENSION_OPTIONS: '/user/admin/extension?userId=',
        // COURSE DETAILS
        EXPORT_COURSE_STRUCTURE: '/course/admin/structure?courseId=',
        // Modules
        GET_MODULES_IN_COURSE: '/course/admin/module/get?courseId=',
        ENABLE_MODULE: '/course/admin/module/enable',
        DISABLE_MODULE: '/course/admin/module/disable',
        ENABLE_MODULE_STUDY_PLANNER:
            '/course/admin/module/study_planner/enable',
        DISABLE_MODULE_STUDY_PLANNER:
            '/course/admin/module/study_planner/disable',
        ADD_MODULE_TO_COURSE: '/course/admin/module/add',
        COPY_MODULE_TO_COURSE: '/course/admin/module/copy',
        COPY_ALL_MODULES_FROM_COURSE: '/course/admin/module/copy_all',
        SEARCH_MODULE: '/course/admin/module/search?query=',
        REORDER_MODULES_IN_COURSE: '/course/admin/modules/reorder',
        DELETE_MODULE: '/course/admin/module/delete',
        UNLINK_MODULE: '/course/admin/module/unlink',
        UPDATE_MODULE_NAME: '/course/admin/module/update',
        UPDATE_MODULE_STUDY_PLANNER_NAME:
            '/course/admin/module/study_planner/name/update',
        GET_MODULE_LINKED_COURSES:
            '/course/admin/module/linked_courses?moduleId=',
        GET_ORPHAN_MODULES: '/course/admin/module/orpaned',
        // Modules Attempt Exclude
        GET_MODULES_ATTEMPT_EXCLUSIONS:
            '/course/admin/module/attempt/exclude/get?moduleCourseMappingId=',
        ADD_MODULE_ATTEMPT_EXCLUSION:
            '/course/admin/module/attempt/exclude/add',
        DELETE_MODULE_ATTEMPT_EXCLUSION:
            '/course/admin/module/attempt/exclude/delete',
        // Sections
        GET_SECTIONS_IN_MODULE: '/course/admin/section/get?id=',
        ENABLE_SECTION: '/course/admin/section/enable',
        DISABLE_SECTION: '/course/admin/section/disable',
        DELETE_COURSE_SECTION: '/course/admin/section/delete',
        GET_SECTION_CONTENT_TYPES: '/course/admin/contenttypes',
        ADD_SECTION_IN_MODULE: '/course/admin/section/add',
        UPLOAD_SECTIONS_IN_MODULE: '/course/admin/section/upload',
        REORDER_SECTIONS_IN_MODULE: '/course/admin/sections/reorder',
        UPDATE_SECTION_NAME: '/course/admin/section/update/name',
        UPDATE_VIDEO_IN_SECTION: '/course/admin/section/update/video',
        COPY_SECTIONS: '/course/admin/section/copy',
        SEARCH_BY_SECTION_NAME: '/course/admin/section/search?query=',
        UPDATE_MCQ_TEST_IN_SECTION: '/course/admin/section/update/mcq_test',
        MOVE_SECTION_UP: '/course/admin/section/move/up',
        MOVE_SECTION_DOWN: '/course/admin/section/move/down',
        MOVE_SECTION_TOP: '/course/admin/section/move/top',
        MOVE_SECTION_BOTTOM: '/course/admin/section/move/bottom',
        UPDATE_SECTION_TAG: '/course/admin/section/tag/update',
        // VIDEOS
        ADD_VIDEO: '/video/admin/add',
        UPDATE_VIDEO_META: '/video/admin/update/meta',
        REPLACE_VIDEO: '/video/admin/replace',
        GET_VDOCIPHER_ID: '/video/admin/vdocipher/get_id?query=',
        GET_GUMLET_ID: '/video/admin/gumlet/get_id?query=',
        SEARCH_VIDEO: '/video/admin/get?search=',
        UPDATE_VIDEO: '/video/admin/update/title',
        DELETE_VIDEO: '/video/admin/delete',
        UPDATE_VIDEO_VDOCIPHER_DATA: '/video/admin/update/vdocipher',
        UPDATE_VIDEO_GUMLET_DATA: '/video/admin/update/gumlet',
        FETCH_AND_ADD_VDOCIPHER: '/video/admin/vdocipher/fetch_and_add',
        FETCH_AND_ADD_GUMLET: '/video/admin/gumlet/fetch_and_add',
        GET_VIDEO_CREDS: '/video/admin/get_creds',
        // FORUMS
        GET_LATEST_TOPICS: '/forum/admin/getlatestposts',
        GET_ACTIVE_TOPICS: '/forum/admin/activeposts',
        GET_UNANSWERED_TOPICS: '/forum/admin/unanswered',
        GET_FORUM_TOPIC_DETAILS: '/forum/admin/post/',
        GET_FORUM_TOPIC_VIDEO_CREDS: '/forum/admin/post/get_video_creds?id=',
        GET_FORUM_GROUP_NAMES: '/forum/groups/names',
        POST_FORUM_REPLY: '/forum/admin/thread/add',
        GET_FORUM_POST_AS_USERS_LIST: '/forum/admin/get_post_as_users',
        ENABLE_FORUM_TOPIC: '/forum/admin/post/enable',
        DISABLE_FORUM_TOPIC: '/forum/admin/post/disable',
        MARK_FORUM_TOPIC_PUBLIC: '/forum/admin/post/public',
        MARK_FORUM_TOPIC_PRIVATE: '/forum/admin/post/private',
        LOCK_FORUM_TOPIC: '/forum/admin/post/lock',
        UNLOCK_FORUM_TOPIC: '/forum/admin/post/unlock',
        UPDATE_FORUM_TOPIC_TITLE: '/forum/admin/post/update_title',
        ENABLE_FORUM_THREAD: '/forum/admin/thread/enable',
        DISABLE_FORUM_THREAD: '/forum/admin/thread/disable',
        GET_OPEN_TOPICS: '/forum/admin/posts/open',
        GET_OPEN_TOPICS_COUNT_BY_GROUP: '/forum/admin/post/open_count_by_group',
        GET_FORUM_QUERY_TYPES: '/forum/admin/querytypes',
        GET_FORUM_POSTS: '/forum/admin/posts',
        SEARCH_FORUM_BY_PRIVATE_NOTES: '/forum/admin/search_private_notes',
        CLOSE_TOPIC: '/forum/admin/post/close',
        OPEN_TOPIC: '/forum/admin/post/open',
        UPDATE_FORUM_TOPIC_GROUP: '/forum/admin/post/group/update',
        GET_TOPICS_COUNT: '/forum/admin/post/count',
        DELETE_THREAD: '/forum/admin/thread/delete',
        MARK_THREAD_AS_BEST_ANSWER: '/forum/admin/thread/markanswer',
        DELETE_TOPIC_FORUM: '/forum/admin/post/delete',
        GET_FORUM_GROUPS: '/forum/groups/names',
        UPDATE_FORUM_THREAD: '/forum/admin/thread/update',
        // Forum Groups
        GET_FORUM_GROUPS_ADMIN: '/forum/admin/group/get',
        ENABLE_FORUM_GROUP: '/forum/admin/group/enable',
        DISABLE_FORUM_GROUP: '/forum/admin/group/disable',
        ADD_FORUM_GROUP: '/forum/admin/group/add',
        UPDATE_FORUM_GROUP: '/forum/admin/group/update',
        REORDER_FORUM_GROUPS: '/forum/admin/group/reorder',
        // Forum Group Stream Mapping
        GET_FORUM_GROUP_STREAM_MAPPINGS:
            '/forum/admin/group/stream/mapping/get?groupId=',
        ADD_FORUM_GROUP_STREAM_MAPPING: '/forum/admin/group/stream/mapping/add',
        DELETE_FORUM_GROUP_STREAM_MAPPING:
            '/forum/admin/group/stream/mapping/delete',
        // Forum Group Course Mapping
        GET_FORUM_GROUP_COURSE_MAPPINGS:
            '/forum/admin/group/course/mapping/get?groupId=',
        ADD_FORUM_GROUP_COURSE_MAPPING: '/forum/admin/group/course/mapping/add',
        ENABLE_FORUM_GROUP_COURSE_MAPPING:
            '/forum/admin/group/course/mapping/enable',
        DISABLE_FORUM_GROUP_COURSE_MAPPING:
            '/forum/admin/group/course/mapping/disable',
        DELETE_FORUM_GROUP_COURSE_MAPPING:
            '/forum/admin/group/course/mapping/delete',
        // Forum Group User Mapping
        GET_FORUM_GROUP_USER_MAPPINGS: '/forum/admin/group_user_mapping/get',
        ADD_FORUM_GROUP_USER_MAPPING: '/forum/admin/group_user_mapping/add',
        DELETE_FORUM_GROUP_USER_MAPPING:
            '/forum/admin/group_user_mapping/delete',
        GET_FORUM_GROUP_USER_MAPPING_OPEN_TOPICS:
            '/forum/admin/group_user_mapping/open_topics?userId=',
        GET_FORUM_MY_OPEN_TOPICS:
            '/forum/admin/group_user_mapping/my_open_topics',
        ENABLE_NEW_TOPIC_WHATSAPP_NOTIFICATION:
            '/forum/admin/group_user_mapping/enable_wa_notificaiton',
        DISABLE_NEW_TOPIC_WHATSAPP_NOTIFICATION:
            '/forum/admin/group_user_mapping/disable_wa_notificaiton',
        // ATTACHMENT
        DOWNLOAD_ATTACHMENT: '/attachment/',
        GET_DOWNLOAD_ATTACHMENT_URL: '/attachment/url/',
        // Notifications
        NOTIFICATION_TO_ALL_USERS: '/firebase/admin/notification/send/all',
        NOTIFICATION_TO_ADMINS: '/firebase/admin/notification/send/admins',
        NOTIFICATION_TO_REGISTERED_USERS:
            '/firebase/admin/notification/registered',
        NOTIFICATION_TO_FOUNDATION_FREE_USERS:
            '/firebase/admin/notification/foundation/free',
        NOTIFICATION_TO_USERS_BY_STREAM: '/firebase/admin/notification/stream',
        NOTIFICATION_BY_FILE_UPLOAD: '/notifications/admin/notification/upload',
        GET_ROUTES: '/mapp/admin/routes',
        // Free Courses
        GET_FREE_COURSES: '/freecourse/admin/get',
        ADD_FREE_COURSE: '/freecourse/admin/add',
        UPDATE_FREE_COURSE: '/freecourse/admin/update',
        DELETE_FREE_COURSE: '/freecourse/admin/delete',
        REORDER_FREE_COURSE: '/freecourse/admin/reorder',
        ENABLE_FREE_COURSE: '/freecourse/admin/enable',
        DISABLE_FREE_COURSE: '/freecourse/admin/disable',
        // Video Vendor
        GET_ALL_VIDEO_VENDORS: '/video/admin/vendor/get',
        ENABLE_VIDEO_VENDOR: '/video/admin/vendor/enable',
        DISABLE_VIDEO_VENDOR: '/video/admin/vendor/disable',
        ADD_VIDEO_VENDOR: '/video/admin/vendor/add',
        UPDATE_VIDEO_VENDOR: '/video/admin/vendor/update',
        GET_VIDEO_VENDOR_ACCESS_LIST:
            '/video/admin/vendor/access/get?vendorId=',
        ADD_COURSE_ACCESS_VIDEO_VENDOR: '/video/admin/vendor/access/add',
        DELETE_COURSE_ACCESS_VIDEO_VENDOR: '/video/admin/vendor/access/delete',
        // Vendor whitelist
        GET_VIDEO_VENDOR_WHITELIST_URLS:
            '/video/admin/vendor/urlwhitelist/get?vendorId=',
        ADD_VIDEO_VENDOR_WHITELIST_URL: '/video/admin/vendor/urlwhitelist/add',
        UPDATE_VIDEO_VENDOR_WHITELIST_URL:
            '/video/admin/vendor/urlwhitelist/update',
        DELETE_VIDEO_VENDOR_WHITELIST_URL:
            '/video/admin/vendor/urlwhitelist/delete',
        // Course Extension
        GET_EXTENSION_PRICES: '/course/admin/extension/price/get',
        ENABLE_EXTENSION_PRICE: '/course/admin/extension/price/enable',
        DISABLE_EXTENSION_PRICE: '/course/admin/extension/price/disable',
        DELETE_EXTENSION_PRICE: '/course/admin/extension/price/delete',
        ADD_EXTENSION_PRICE: '/course/admin/extension/price/add',
        UPDATE_EXTENSION_PRICE: '/course/admin/extension/price/update',
        GET_EXTENSION_ATTEMPTS: '/course/admin/extension/attempt/get',
        ENABLE_EXTENSION_ATTEMPT: '/course/admin/extension/attempt/enable',
        DISABLE_EXTENSION_ATTEMPT: '/course/admin/extension/attempt/disable',
        ADD_EXTENSION_ATTEMPT: '/course/admin/extension/attempt/add',
        UPDATE_EXTENSION_ATTEMPT: '/course/admin/extension/attempt/update',
        DELETE_EXTENSION_ATTEMPT: '/course/admin/extension/attempt/delete',
        GET_EXTENSION_CUSTOM_ENROLLMENT:
            '/course/admin/extension/enroll_custom/get?extensionPriceId=',
        ADD_EXTENSION_CUSTOM_ENROLLMENT:
            '/course/admin/extension/enroll_custom/add',
        DELETE_EXTENSION_CUSTOM_ENROLLMENT:
            '/course/admin/extension/enroll_custom/delete',
        // Course reviews
        GET_COURSE_REVIEW_TYPES: '/course/admin/review/filtertypes',
        GET_COURSE_REVIEWS: '/course/admin/reviews',
        ENABLE_COURSE_REVIEW: '/course/admin/review/enable',
        DISABLE_COURSE_REVIEW: '/course/admin/review/disable',
        DELETE_COURSE_REVIEW: '/course/admin/review/delete',
        CLEAR_COURSE_REVIEW_COMMENTS: '/course/admin/review/comments/clear',
        COURSE_REVIEW: {
            // Group
            GET_GROUPS: '/course/admin/review/group/get',
            ADD_GROUP: '/course/admin/review/group/add',
            UPDATE_GROUP: '/course/admin/review/group/update',
            DELETE_GROUP: '/course/admin/review/group/delete',
            // Group Courses
            GET_GROUP_COURSES: '/course/admin/review/group/course/get?groupId=',
            ADD_GROUP_COURSE: '/course/admin/review/group/course/add',
            DELETE_GROUP_COURSE: '/course/admin/review/group/course/delete',
        },
        // Wall Of Fame
        GET_WOF: '/wof/admin/get',
        ENABLE_WOF: '/wof/admin/enable',
        DISABLE_WOF: '/wof/admin/disable',
        ADD_WOF: '/wof/admin/add',
        UPDATE_WOF: '/wof/admin/update',
        DELETE_WOF: '/wof/admin/delete',
        REORDER_WOF: '/wof/admin/reorder',
        PAGES_WOF: '/wof/admin/pages',
        COPY_WOF_TO_STARRED: '/wof/admin/copy_to_starred',
        WOF_GET_STREAM_FILTERS: '/wof/admin/stream_filters',
        WOF_GET_STREAM_LEVEL_FILTERS: '/wof/admin/stream_level_filters',
        WOF_GET_ATTEMPT_FILTERS: '/wof/admin/attempt_filters',
        // Demo Videos
        GET_DEMO_VIDEOS: '/demo/admin/get',
        ADD_DEMO_VIDEO: '/demo/admin/add',
        UPDATE_DEMO_VIDEO: '/demo/admin/update',
        DELETE_DEMO_VIDEO: '/demo/admin/delete',
        ENABLE_DEMO_VIDEO: '/demo/admin/enable',
        DISABLE_DEMO_VIDEO: '/demo/admin/disable',
        REORDER_DEMO_VIDEOS: '/demo/admin/reorder',
        // Popular Courses
        GET_POPULAR_COURSE_GROUPS: '/course/admin/popular/group/get',
        ADD_POPULAR_COURSE_GROUP: '/course/admin/popular/group/add',
        UPDATE_POPULAR_COURSE_GROUP: '/course/admin/popular/group/update',
        ENABLE_POPULAR_COURSE_GROUP: '/course/admin/popular/group/enable',
        DISABLE_POPULAR_COURSE_GROUP: '/course/admin/popular/group/disable',
        DELETE_POPULAR_COURSE_GROUP: '/course/admin/popular/group/delete',
        REORDER_POPULAR_COURSE_GROUPS: '/course/admin/popular/group/reorder',
        GET_POPULAR_COURSES: '/course/admin/popular/course/get?groupName=',
        ADD_POPULAR_COURSE: '/course/admin/popular/course/add',
        ENABLE_POPULAR_COURSE: '/course/admin/popular/course/enable',
        DISABLE_POPULAR_COURSE: '/course/admin/popular/course/disable',
        DELETE_POPULAR_COURSE: '/course/admin/popular/course/delete',
        REORDER_POPULAR_CORUSES: '/course/admin/popular/course/reorder',
        // iCash
        AWARD_ICASH_TO_ALL_USERS: '/credits/admin/add/all',
        AWARD_ICASH_BY_EXCEL_UPLOAD: '/credits/admin/add/upload',
        // Reports
        GENERATE_USER_REPORT: '/report/admin/users',
        GENERATE_DND_SMS_REPORT: '/report/admin/dnd_sms',
        GENERATE_TRANSACTIONS_REPORT: '/report/admin/transactions',
        GENERATE_TRANSACTION_ITEMS_REPORT: '/report/admin/transaction_items',
        GENERATE_SUBJECT_WISE_REVENUE_REPORT:
            '/report/admin/subjectwiserevenue',
        GENERATE_FACULTY_REVENUE_REPORT: '/report/admin/faculty/revenuereport',
        GENERATE_PAID_USERS_REPORT: '/report/admin/users/subscribed',
        GENERATE_COURSE_COMPLETION_REPORT: '/report/admin/subject_completion',
        GENERATE_COUPON_WISE_SALES_REPORT: '/report/admin/sales/coupon_wise',
        DOWNLOAD_COUPON_WISE_SALES_REPORT:
            '/report/admin/sales/coupon_wise/download',
        // Faculty Stats
        GET_FACULTY_SALES_MONTH_WISE: '/stats/admin/facultyrevenue/monthwise',
        GET_ALL_FACULTY_SALES: '/stats/admin/facultyrevenue/all',
        // Config
        GET_CONFIG: '/config/admin/get',
        ADD_CONFIG: '/config/admin/add',
        UPDATE_CONFIG: '/config/admin/update',
        DELETE_CONFIG: '/config/admin/delete',
        GET_CONFIG_KEYS: '/config/admin/keys',
        // Surveys
        GET_SURVEYS: '/survey/admin/get',
        GET_SURVEY_SURVEY_ID: '/survey/admin/get/',
        ENABLE_SURVEY: '/survey/admin/enable',
        DISABLE_SURVEY: '/survey/admin/disable',
        ENABLE_SURVEY_RESULTS: '/survey/admin/results/enable',
        DISABLE_SURVEY_RESULTS: '/survey/admin/results/disable',
        DELETE_SURVEY: '/survey/admin/delete',
        ADD_SURVEY: '/survey/admin/add',
        UPDATE_SURVEY: '/survey/admin/update',
        // Survey Questions
        GET_QUESTIONS_IN_SURVEY: '/survey/admin/questions/get?surveyId=',
        GET_SURVEY_QUESTION_TYPES: '/survey/admin/question/types',
        GET_SURVEY_OPTION_TYPES: '/survey/admin/option/types',
        ADD_SURVEY_QUESTION: '/survey/admin/question/add',
        UPDATE_SURVEY_QUESTION: '/survey/admin/question/update',
        DELETE_SURVEY_QUESTION: '/survey/admin/question/delete',
        REORDER_SURVEY_QUESTIONS: '/survey/admin/questions/reorder',
        // Survey Option
        ADD_SURVEY_OPTION: '/survey/admin/option/add',
        UPDATE_SURVEY_OPTION: '/survey/admin/option/update',
        DELETE_SURVEY_OPTION: '/survey/admin/option/delete',
        REORDER_SURVEY_OPTIONS: '/survey/admin/options/reorder',
        // Survey Results
        GET_SURVEY_RESPONSES: '/survey/admin/results?surveyId=',
        DOWNLOAD_SURVEY_RESPONSES: '/survey/admin/downloadresponses?surveyId=',
        // Support
        CREATE_FRESHDESK_TICKET: '/support/admin/freshdesk/create',
        STREAM_PAGE: {
            GET_STREAM_PAGES: '/streampage/admin/get',
            ADD_STREAM_PAGE: '/streampage/admin/add',
            UPDATE_STREAM_PAGE: '/streampage/admin/update',
            ENABLE_STREAM_PAGE: '/streampage/admin/enable',
            DISABLE_STREAM_PAGE: '/streampage/admin/disable',
            DELETE_STREAM_PAGE: '/streampage/admin/delete',
            REORDER_STREAM_PAGES: '/streampage/admin/reorder',
            GET_STREAM_PAGE_CONTENT: '/streampage/admin/content/get?pageId=',
            ADD_STREAM_PAGE_CONTENT: '/streampage/admin/content/add',
            UPDATE_STREAM_PAGE_CONTENT: '/streampage/admin/content/update',
            ENABLE_STREAM_PAGE_CONTENT: '/streampage/admin/content/enable',
            DISABLE_STREAM_PAGE_CONTENT: '/streampage/admin/content/disable',
            DELETE_STREAM_PAGE_CONTENT: '/streampage/admin/content/delete',
            REORDER_STREAM_PAGE_CONTENT: '/streampage/admin/content/reorder',
        },
        TRANSACTION_QUEUE: {
            GET_TRANSACTIONS_IN_QUEUE: '/transaction/admin/queue/get',
            ADD_TRANSACTION_TO_QUEUE: '/transaction/admin/queue/add',
            UPDATE_TRANSACTION_TO_QUEUE: '/transaction/admin/queue/update',
            APPROVE_TRANSACTION_TO_QUEUE: '/transaction/admin/queue/approve',
            DELETE_TRANSACTION_TO_QUEUE: '/transaction/admin/queue/delete',
        },
        BLOG: {
            // Authors
            GET_AUTHORS: '/blog/admin/authors/get',
            ADD_AUTHOR: '/blog/admin/author/add',
            UPDATE_AUTHOR: '/blog/admin/author/update',
            DELETE_AUTHOR: '/blog/admin/author/delete',
            // Tags
            GET_TAGS: '/blog/admin/tags/get',
            ADD_TAG: '/blog/admin/tag/add',
            UPDATE_TAG: '/blog/admin/tag/update',
            DELETE_TAG: '/blog/admin/tag/delete',
            // Blog Page
            GET_PAGES: '/blog/admin/pages/get',
            SEARCH_PAGES: '/blog/admin/pages/search?query=',
            GET_PAGE: '/blog/admin/page/get',
            GET_PAGES_LIST: '/blog/admin/pages/list',
            ADD_PAGE: '/blog/admin/page/add',
            UPDATE_PAGE: '/blog/admin/page/update',
            DELETE_PAGE: '/blog/admin/page/delete',
            PUBLISH_PAGE: '/blog/admin/page/publish',
            UNPUBLISH_PAGE: '/blog/admin/page/unpublish',
            UPDATE_TAGS_IN_PAGE: '/blog/admin/page/tags/update',
            UPDATE_AUTHOR_IN_PAGE: '/blog/admin/page/author/update',
            GET_PAGE_CATEGORIES_MAPPINGS:
                '/blog/admin/page/category/get?blogId=',
            // Super Blog
            GET_SUPER_BLOGS: '/blog/admin/super/get',
            ADD_SUPER_BLOG: '/blog/admin/super/add',
            DELETE_SUPER_BLOG: '/blog/admin/super/delete',
            REORDER_SUPER_BLOG: '/blog/admin/super/reorder',
            ENABLE_SUPER_BLOG: '/blog/admin/super/enable',
            DISABLE_SUPER_BLOG: '/blog/admin/super/disable',
            // Category
            GET_BLOG_CATEGORIES: '/blog/admin/category/get',
            ADD_BLOG_CATEGORY: '/blog/admin/category/add',
            UPDATE_BLOG_CATEGORY: '/blog/admin/category/update',
            DELETE_BLOG_CATEGORY: '/blog/admin/category/delete',
            ENABLE_BLOG_CATEGORY: '/blog/admin/category/enable',
            DISABLE_BLOG_CATEGORY: '/blog/admin/category/disable',
            REORDER_BLOG_CATEGORY: '/blog/admin/category/reorder',
            // Category Blog Mapping
            GET_BLOGS_IN_CATEGORY: '/blog/admin/category/blogs/get?categoryId=',
            ADD_BLOG_TO_CATEGORY: '/blog/admin/category/blogs/add',
            DELETE_BLOG_FROM_CATEGORY: '/blog/admin/category/blogs/delete',
            ENABLE_BLOG_IN_CATEGORY: '/blog/admin/category/blogs/enable',
            DISABLE_BLOG_IN_CATEGORY: '/blog/admin/category/blogs/disable',
            // Comments
            GET_BLOGS_WITH_OPEN_COMMENTS_LIST: '/blog/admin/comments/open/list',
            GET_BLOG_WITH_OPEN_COMMENTS: '/blog/admin/comments/open?blogId=',
            DELETE_BLOG_COMMENT: '/blog/admin/comments/delete',
            MARK_BLOG_COMMENT_ANSWERED: '/blog/admin/comments/answered',
            MARK_BLOG_COMMENT_UNANSWERED: '/blog/admin/comments/unanswered',
            ADD_BLOG_COMMENT: '/blog/admin/comments/add',
            MARK_ALL_BLOG_COMMENTS_AS_ANSWERED:
                '/blog/admin/comments/markallanswered',
        },
        UPLOAD_TO_S3: '/upload/admin/s3',
        UPLOAD_TO_S3_MCQ: '/upload/admin/s3/mcq',
        FACULTY: {
            // Faculty
            GET_ALL_FACULTY: '/mp/admin/faculty/get/all',
            ADD_FACULTY: '/mp/admin/faculty/add',
            UPDATE_FACULTY: '/mp/admin/faculty/update',
            LINK_USER: '/faculty/admin/linkuser',
            CLEAR_USER_LINK: '/faculty/admin/clearuserlink',
            // Contracts
            GET_FACULTY_CONTRACTS: '/faculty/admin/contract/get?facultyId=',
            ADD_FACULTY_CONTRACT: '/faculty/admin/contract/add',
            UPDATE_FACULTY_CONTRACT: '/faculty/admin/contract/update',
            DELETE_FACULTY_CONTRACT: '/faculty/admin/contract/delete',
            ENABLE_FACULTY_CONTRACT: '/faculty/admin/contract/enable',
            DISABLE_FACULTY_CONTRACT: '/faculty/admin/contract/disable',
            GET_ALL_RELATED_COURSES_IN_CONTRACT:
                '/faculty/admin/contract/getrelatedcourses?contractId=',
            GET_ALL_RELATED_COURSES_IN_ALL_CONTRACTS:
                '/faculty/admin/contract/getrelatedcoursesall?facultyId=',
            GET_MISSING_COURSES_TO_MAP:
                '/faculty/admin/contract/getmissingcoursemappings?facultyId=',
            GET_MISSING_WEIGHTAGES_TO_MAP:
                '/faculty/admin/contract/getmissingcourseweightages?facultyId=',
            // Slabs
            GET_FACULTY_SLABS: '/faculty/admin/contract/slabs/get?contractId=',
            ADD_FACULTY_SLAB: '/faculty/admin/contract/slab/add',
            UPDATE_FACULTY_SLAB: '/faculty/admin/contract/slab/update',
            DELETE_FACULTY_SLAB: '/faculty/admin/contract/slab/delete',
            // Contract Courses
            GET_CONTRACT_COURSES:
                '/faculty/admin/contract/course/get?contractId=',
            ADD_CONTRACT_COURSE: '/faculty/admin/contract/course/add',
            DELETE_CONTRACT_COURSE: '/faculty/admin/contract/course/delete',
            // Sales
            GET_SALES_SUMMARY: '/faculty/admin/sales/summary?facultyId=',
            GENERATE_SALES_REPORT: '/faculty/admin/sales/report/generate',
            GET_SALE_COURSE_SUMMARY:
                '/faculty/admin/sales/coursesummary?saleSummaryUID=',
            GET_SALE_COURSE_SPLIT:
                '/faculty/admin/sales/courseSplit?courseSummaryUID=',
            // Content Recording Split
            GET_CONTENT_RECORDING_SPLIT:
                '/faculty/admin/sales/contentrecordingsplit/get?saleSummaryUID=',
            ADD_CONTENT_RECORDING_SPLIT:
                '/faculty/admin/sales/contentrecordingsplit/add',
            UPDATE_CONTENT_RECORDING_SPLIT:
                '/faculty/admin/sales/contentrecordingsplit/update',
            DELETE_CONTENT_RECORDING_SPLIT:
                '/faculty/admin/sales/contentrecordingsplit/delete',
            // Invoice
            GET_FACULTY_INVOICES: '/faculty/admin/invoice/get?facultyId=',
            DOWNLOAD_FACULTY_INVOICES:
                '/faculty/admin/invoice/download?facultyId=',
            GENERATE_FACULTY_INVOICE: '/faculty/admin/invoice/generate',
            MARK_FACULTY_INVOICE_AS_PAID: '/faculty/admin/invoice/markpaid',
            MARK_FACULTY_INVOICE_AS_UNPAID: '/faculty/admin/invoice/markunpaid',
            DELETE_FACULTY_INVOICE: '/faculty/admin/invoice/delete',
            // Stats
            GET_COURSES_SUMMARY:
                '/faculty/admin/courses/summary/split/get?facultyId=',
            GET_STUDENTLIST: '/faculty/admin/course/students/get',
            GET_FACULTY_COMMISSION_REPORT: '/faculty/admin/report/comission',
        },
        FAQ: {
            // FAQ Groups
            GET_FAQ_GROUPS: '/faq/admin/group/get',
            ADD_FAQ_GROUP: '/faq/admin/group/add',
            UPDATE_FAQ_GROUP: '/faq/admin/group/update',
            DELETE_FAQ_GROUP: '/faq/admin/group/delete',
            REORDER_FAQ_GROUP: '/faq/admin/group/reorder',
            ENABLE_FAQ_GROUP: '/faq/admin/group/enable',
            DISABLE_FAQ_GROUP: '/faq/admin/group/disable',
            // FAQ
            GET_FAQ_PAGE_NAMES: '/faq/admin/pagenames/get',
            GET_FAQS: '/faq/admin/get',
            ADD_FAQ: '/faq/admin/add',
            UPDATE_FAQ: '/faq/admin/update',
            DELETE_FAQ: '/faq/admin/delete',
            ENABLE_FAQ: '/faq/admin/enable',
            DISABLE_FAQ: '/faq/admin/disable',
            REORDER_FAQ: '/faq/admin/reorder',
        },
        ECOMMERCE: {
            // Cart drop discount
            GET_COUPON_CART_DROP_DISCOUNTS:
                '/coupon/admin/cart_drop/discount/get',
            ADD_COUPON_CART_DROP_DISCOUNT:
                '/coupon/admin/cart_drop/discount/add',
            UPDATE_COUPON_CART_DROP_DISCOUNT:
                '/coupon/admin/cart_drop/discount/update',
            DELETE_COUPON_CART_DROP_DISCOUNT:
                '/coupon/admin/cart_drop/discount/delete',
            // Cart drop coupons
            GET_CART_DROP_COUPONS: '/coupon/admin/cart_drop/get',
            SEARCH_CART_DROP_COUPONS: '/coupon/admin/cart_drop/search?query=',
            CREATE_CART_DROP_COUPON: '/coupon/admin/cart_drop/create',
            UPDATE_CART_DROP_COUPON: '/coupon/admin/cart_drop/update',
            DELETE_CART_DROP_COUPON: '/coupon/admin/cart_drop/delete',
            // Combo Discount
            GET_COMBO_DISCOUNTS: '/coupon/admin/combo_discount/get',
            ADD_COMBO_DISCOUNT: '/coupon/admin/combo_discount/add',
            UPDATE_COMBO_DISCOUNT: '/coupon/admin/combo_discount/update',
            ENABLE_COMBO_DISCOUNT: '/coupon/admin/combo_discount/enable',
            DISABLE_COMBO_DISCOUNT: '/coupon/admin/combo_discount/disable',
            DELETE_COMBO_DISCOUNT: '/coupon/admin/combo_discount/delete',
            // Combo Discount Config
            GET_COMBO_DISCOUNT_CONFIG:
                '/coupon/admin/combo_discount/config/get?comboId=',
            ADD_COMBO_DISCOUNT_CONFIG:
                '/coupon/admin/combo_discount/config/add',
            UPDATE_COMBO_DISCOUNT_CONFIG:
                '/coupon/admin/combo_discount/config/update',
            ENABLE_COMBO_DISCOUNT_CONFIG:
                '/coupon/admin/combo_discount/config/enable',
            DISABLE_COMBO_DISCOUNT_CONFIG:
                '/coupon/admin/combo_discount/config/disable',
            DELETE_COMBO_DISCOUNT_CONFIG:
                '/coupon/admin/combo_discount/config/delete',
            // Combo Discount Course
            GET_COMBO_DISCOUNT_COURSES:
                '/coupon/admin/combo_discount/course/get?comboId=',
            ADD_COMBO_DISCOUNT_COURSE:
                '/coupon/admin/combo_discount/course/add',
            ENABLE_COMBO_DISCOUNT_COURSE:
                '/coupon/admin/combo_discount/course/enable',
            DISABLE_COMBO_DISCOUNT_COURSE:
                '/coupon/admin/combo_discount/course/disable',
            DELETE_COMBO_DISCOUNT_COURSE:
                '/coupon/admin/combo_discount/course/delete',
        },
        DRIP: {
            GET_DRIP_FLOWS: '/drip/admin/flow/get',
            GET_DRIP_TYPES: '/drip/admin/type/get',
            GET_DRIPS: '/drip/admin/get',
            ADD_DRIP: '/drip/admin/add',
            UPDATE_DRIP: '/drip/admin/update',
            DELETE_DRIP: '/drip/admin/delete',
            ENABLE_DRIP: '/drip/admin/enable',
            DISABLE_DRIP: '/drip/admin/disable',
            TEST_DRIP: '/drip/admin/test',
            // Stream Mapping
            GET_DRIP_STREAM_MAPPINGS: '/drip/admin/stream_mapping/get?dripId=',
            ADD_DRIP_STREAM_MAPPING: '/drip/admin/stream_mapping/add',
            DELETE_DRIP_STREAM_MAPPING: '/drip/admin/stream_mapping/delete',
            ENABLE_DRIP_STREAM_MAPPINGS: '/drip/admin/stream_mapping/enable',
            DISABLE_DRIP_STREAM_MAPPINGS: '/drip/admin/stream_mapping/disable',
        },
        STUDY_PLANNER: {
            // Config
            GET_CONFIG: '/study_planner/admin/config/get',
            ADD_CONFIG: '/study_planner/admin/config/add',
            UPDATE_CONFIG: '/study_planner/admin/config/update',
            GET_CONFIG_NAMES: '/study_planner/admin/config/names',
            // Course Config
            GET_COURSE_CONFIG: '/study_planner/admin/course_config/get',
            ADD_COURSE_CONFIG: '/study_planner/admin/course_config/add',
            UPDATE_COURSE_CONFIG: '/study_planner/admin/course_config/update',
            DELETE_COURSE_CONFIG: '/study_planner/admin/course_config/delete',
            REORDER_COURSE_CONFIG: '/study_planner/admin/course_config/reorder',
            GET_COURSE_CONFIG_TYPES: '/study_planner/admin/course_config/types',
            // Course Selection Config
            GET_COURSE_SELECTION_CONFIG:
                '/study_planner/admin/course_selection_config/get',
            ADD_COURSE_SELECTION_CONFIG:
                '/study_planner/admin/course_selection_config/add',
            DELETE_COURSE_SELECTION_CONFIG:
                '/study_planner/admin/course_selection_config/delete',
            REORDER_COURSE_SELECTION_CONFIG:
                '/study_planner/admin/course_selection_config/reorder',
            ENABLE_COURSE_SELECTION_CONFIG:
                '/study_planner/admin/course_selection_config/enable',
            DISABLE_COURSE_SELECTION_CONFIG:
                '/study_planner/admin/course_selection_config/disable',
            // Session Config
            GET_SESSION_CONFIG: '/study_planner/admin/session_config/get',
            ADD_SESSION_CONFIG: '/study_planner/admin/session_config/add',
            UPDATE_SESSION_CONFIG: '/study_planner/admin/session_config/update',
            DELETE_SESSION_CONFIG: '/study_planner/admin/session_config/delete',
            // Study Plan
            CREATE_STUDY_PLAN: '/study_planner/create',
            DELETE_STUDY_PLAN: '/study_planner/delete',
            DOWNLOAD_STUDY_PLAN: '/study_planner/download',
            GET_STUDY_PLAN_STATUS: '/study_planner/status',
        },
        SALES: {
            GET_CALLS_REPORT_CURRENT_DAY:
                '/report/admin/sales_team/calls_made/current_day',
            GENERATE_CALLS_REPORT: '/report/admin/sales_team/calls_made',
            GENERATE_CALLS_MADE_DETAILED_REPORT:
                '/report/admin/sales_team/calls_made_detailed',
            DOWNLOAD_CALLS_MADE_DETAILED_REPORT:
                '/report/admin/sales_team/calls_made_detailed/download',
            // External Sales
            GET_EXTERNAL_SALES_COMPANY_LIST:
                '/external_sales/admin/company/get',
            GET_EXTERNAL_SALES_COMPANY_NAMES:
                '/external_sales/admin/company/get_names',
            ADD_EXTERNAL_SALES_COMPANY: '/external_sales/admin/company/add',
            UPDATE_EXTERNAL_SALES_COMPANY:
                '/external_sales/admin/company/update',
            DELETE_EXTERNAL_SALES_COMPANY:
                '/external_sales/admin/company/delete',
            GET_EXTERNAL_SALES_COMPANY_MEMBERS:
                '/external_sales/admin/member/get?companyId=',
            ADD_EXTERNAL_SALES_COMPANY_MEMBER:
                '/external_sales/admin/member/add',
            DELETE_EXTERNAL_SALES_COMPANY_MEMBER:
                '/external_sales/admin/member/delete',
            ENABLE_EXTERNAL_SALES_COMPANY_MEMBER:
                '/external_sales/admin/member/enable',
            DISABLE_EXTERNAL_SALES_COMPANY_MEMBER:
                '/external_sales/admin/member/disable',
            // External Sales Leads
            EXTERNAL_SALES_GET_NEW_LEADS: '/external_sales/admin/leads/new',
            EXTERNAL_SALES_GET_OLD_LEADS: '/external_sales/admin/leads/old',
            EXTERNAL_SALES_SEARCH_LEAD:
                '/external_sales/admin/lead/search?phone=',
            EXTERNAL_SALES_GET_LEAD_DETAILS:
                '/external_sales/admin/lead/get?userId=',
            EXTERNAL_SALES_GET_LEAD_TRANSACTIONS:
                '/external_sales/admin/lead/transactions?userId=',
            EXTERNAL_SALES_GET_LEAD_COURSE_DETAILS:
                '/external_sales/admin/lead/courses?userId=',
            EXTERNAL_SALES_GET_LEAD_ICASH_BALANCE:
                '/external_sales/admin/lead/icash?userId=',
            EXTERNAL_SALES_GET_LEAD_ICASH_HISTORY:
                '/external_sales/admin/lead/icash/history?userId=',
            EXTERNAL_SALES_ASSIGN_LEAD: '/external_sales/admin/lead/assign',
            EXTERNAL_SALES_BULK_ASSIGN_LEADS:
                '/external_sales/admin/lead/assign/bulk',
            EXTERNAL_SALES_UPDATE_LEAD: '/external_sales/admin/lead/update',
            EXTERNAL_SALES_UPDATE_LEAD_REMINDER_DATE:
                '/external_sales/admin/lead/update/reminder_date',
            EXTERNAL_SALES_ADD_LEAD_COMMENTS:
                '/external_sales/admin/lead/comments/add',
            EXTERNAL_SALES_GET_ASSIGNED_TO_FILTER_DATA:
                '/external_sales/admin/get_assigned_to_filter_data',
            EXTERNAL_SALES_LEAD_ADD_CALL_ENTRY:
                '/external_sales/admin/lead/add_call_entry',
            EXTERNAL_SALES_LEAD_GET_LATEST_COMMENTS:
                '/external_sales/admin/lead/comments/latest?userId=',
            EXTERNAL_SALES_LEAD_GET_ALL_COMMENTS:
                '/external_sales/admin/lead/comments/get?userId=',
            // External Sales Report
            EXTERNAL_SALES_GET_CURRENT_DAY_CALLS_REPORT:
                '/external_sales/admin/report/calls_made/current_day',
            EXTERNAL_SALES_GET_CALLS_MADE_REPORT:
                '/external_sales/admin/report/calls_made',
            EXTERNAL_SALES_GET_CALLS_MADE_REPORT_DETAILED:
                '/external_sales/admin/report/calls_made_detailed',
            EXTERNAL_SALES_DOWNLOAD_CALLS_MADE_REPORT_DETAILED:
                '/external_sales/admin/report/calls_made_detailed/download',
            EXTERNAL_SALES_DASHBOARD: '/external_sales/admin/report/dashboard',
            EXTERNAL_SALES_UNATTENDED_LEADS_STATS:
                '/external_sales/admin/report/unattended_leads_stats',
        },
        USER: {
            ADD_COURSES_BY_PURCHASE: '/user/admin/add_courses_by_purchase',
            GET_USER_ACQUITION_INFO: '/user/admin/acquisition_info?userId=',
        },
        NOTIFICATION: {
            GET_NOTIFICATIONS: '/notifications/admin/get',
            ADD_NOTIFICATIONS: '/notifications/admin/add',
            UPDATE_NOTIFICATIONS: '/notifications/admin/update',
            DELETE_NOTIFICATIONS: '/notifications/admin/delete',
            SEARCH_DND_PHONE: '/notifications/admin/dnd_phone/search?filter=',
            ADD_DND_PHONE: '/notifications/admin/dnd_phone/add',
            DELETE_DND_PHONE: '/notifications/admin/dnd_phone/delete',
            GET_WHATSAPP_TEMPLATE_NAMES:
                '/notifications/admin/whatsapp_template/get',
            ADD_WHATSAPP_TEMPLATE: '/notifications/admin/whatsapp_template/add',
            DELETE_WHATSAPP_TEMPLATE:
                '/notifications/admin/whatsapp_template/delete',
            GET_WHATSAPP_NOTIFICATIONS: '/notifications/admin/whatsapp/get',
            ADD_WHATSAPP_NOTIFICATION: '/notifications/admin/whatsapp/add',
            UPDATE_WHATSAPP_NOTIFICATION:
                '/notifications/admin/whatsapp/update',
            DELETE_WHATSAPP_NOTIFICATION:
                '/notifications/admin/whatsapp/delete',
            GET_USERS_NOTIFICATION_COUNT:
                '/notifications/admin/whatsapp/users_count',
            TEST_WHATSAPP_NOTIFICATION: '/notifications/admin/whatsapp/test',
            GET_WHATSAPP_NOTIFICATION_STATUS_SPLIT:
                '/notifications/admin/whatsapp/status_split?notificationId=',
            GET_WHATSAPP_NOTIFICATION_FAILURE_STATUS_SPLIT:
                '/notifications/admin/whatsapp/failure_status_split?notificationId=',
            GET_DEEPLINKS: '/notifications/admin/deeplink/get',
            GENERATE_DEEPLINK: '/admin/generate/deep_link',
            // Sending Summary
            GET_SENDING_SUMMARY_BY_MONTH:
                '/notification_sending_summary/admin/get_by_month?limit=',
            GET_SENDING_SUMMARY_MONTH_SPLIT:
                '/notification_sending_summary/admin/get_split_month',
            GET_SUMMARY_BY_DAY:
                '/notification_sending_summary/admin/get_by_day',
        },
        SHORT_LINK: {
            GET: '/sl/admin/get?query=',
            GET_LINK: '/sl/admin/get/',
            ADD: '/sl/admin/add',
            UPDATE: '/sl/admin/update',
            UPDATE_LINK_ID: '/sl/admin/update_link_id',
            DELETE: '/sl/admin/delete',
            ENABLE: '/sl/admin/enable',
            DISABLE: '/sl/admin/disable',
        },
        ANALYTICS: {
            GET_COURSE_COMPLETION_PERCENT_BY_ATTEMPT:
                '/analytics/admin/course_completion_by_attempt',
            GET_COURSE_COMPLETION_PERCENT_BY_ATTEMPT_ON_DATE:
                '/analytics/admin/course_completion_by_attempt_on_date',
            USER: {
                GET_WATCH_TIME_BY_MONTH:
                    '/analytics/admin/user/watchtime/month?limit=',
                GET_WATCH_TIME_BY_DAY:
                    '/analytics/admin/user/watchtime/day?monthLabel=',
                GET_COURSE_WATCH_TIME_BY_MONTH:
                    '/analytics/admin/user/watchtime/course/month',
                GET_COURSE_WATCH_TIME_BY_DAY:
                    '/analytics/admin/user/watchtime/course/day',
                GET_COURSE_WATCH_TIME_FILTER:
                    '/analytics/admin/user/watchtime/course/filters',
                GET_COURSE_ATTEMPTS: '/course/admin/attempts',
                GET_COURSES_BY_ATTEMPT: '/course/admin/attempt/courses',
                GET_COURSE_COMPLETION_DATA:
                    '/analytics/admin/user/course_completion',
                GET_ATTEMPT_WISE_STATS:
                    '/nudge/admin/attempt_wise_stats?userId=',
            },
        },
        LIVE_CLASS_SCHEDULE: {
            GET_SCHEDULE: '/live_class_schedule/admin/get/all',
            GET_SCHEDULE_BY_UID: '/live_class_schedule/admin/get?uid=',
            ADD_SCHEDULE: '/live_class_schedule/admin/add',
            UPDATE_SCHEDULE: '/live_class_schedule/admin/update',
            ENABLE_SCHEDULE: '/live_class_schedule/admin/enable',
            DISABLE_SCHEDULE: '/live_class_schedule/admin/disable',
            DELETE_SCHEDULE: '/live_class_schedule/admin/delete',
            GET_CLASS_ON_TYPE: '/live_class_schedule/admin/class_on_types',
        },
        STATS: {
            GET_PURCHASE_STATS_BY_MONTH: '/stats/admin/purchase_by_month',
            GET_PURCHASE_STATS_BY_DAY: '/stats/admin/purchase_by_day',
            GET_COURIER_STATS: '/stats/admin/courier',
            GET_FIRST_PURCHASE_STATS: '/stats/admin/revenue/first_purchase',
            GET_FIRST_PURCHASE_STATS_BY_JOINING_DATE:
                '/stats/admin/revenue/first_purchase_by_joining_date',
            GET_PURCHASE_AGING_STATS:
                '/stats/admin/revenue/purchase_aging_split',
            GET_FIRST_VS_REPEAT_AGING_STATS:
                '/stats/admin/revenue/first_repeat_purchase_aging_split',
            GET_PURCHASE_STATS_BY_ATTEMPT: '/stats/admin/purchase_by_attempt',
        },
        EMAIL: {
            GET_EMAIL_TEMPLATES: '/email/admin/template/get',
            GET_EMAIL_ALL_TEMPLATES: '/email/admin/template/get/all',
            ADD_EMAIL_TEMPLATE: '/email/admin/template/add',
            UPDATE_EMAIL_TEMPLATE: '/email/admin/template/update',
            DELETE_EMAIL_TEMPLATE: '/email/admin/template/delete',
            ENABLE_EMAIL_TEMPLATE: '/email/admin/template/enable',
            DISABLE_EMAIL_TEMPLATE: '/email/admin/template/disable',
            GET_EMAIL_TEMPLATE_DATA: '/email/admin/template/data/get',
            TEST_EMAIL_TEMPLATE: '/email/admin/template/test',
            // Notification
            GET_EMAIL_NOTIFICATIONS: '/email/admin/get',
            ADD_EMAIL_NOTIFICATION: '/email/admin/add',
            UPDATE_EMAIL_NOTIFICATION: '/email/admin/update',
            DELETE_EMAIL_NOTIFICATION: '/email/admin/delete',
            TEST_EMAIL_NOTIFICATION: '/email/admin/test',
            GET_EMAIL_OPEN_RATE: '/email/admin/open_rate',
            REFRESH_EMAIL_STATS: '/email/admin/refresh_stats',
        },
        MCQ: {
            //Test Sections
            GET_TEST_SECTIONS: '/mcq/admin/test/section_mapping/get?testId=',
            ADD_TEST_SECTION: '/mcq/admin/test/section_mapping/add',
            UPDATE_TEST_SECTION: '/mcq/admin/test/section_mapping/update',
            DELETE_TEST_SECTION: '/mcq/admin/test/section_mapping/delete',
            // Questions
            DOWNLOAD_QUESTIONS_BY_TEST: '/mcq/admin/download/subject',
        },
        FORUMS: {
            GET_LEADERBOARD_CURRENT: '/forum/leaderboard/current',
            GET_LEADERBOARD_YEARLY: '/forum/leaderboard/yearly',
            GET_LEADERBOARD_CUSTOM: '/forum/admin/leaderboard',
            GET_ADMIN_RESPONSES_STATS: '/forum/admin/responses_count_admin',
        },
        SEARCH: {
            FORUM: '/public/search/forum_search?query=',
            ALL: '/public/search/site_search?query=',
            FREE_RESOURCES: '/public/search/free_resources_search?query=',
            NEWS: '/public/search/news_search?query=',
            BLOGS: '/public/search/blogs_search?query=',
        },
        BOOKS: {
            // Books
            GET_BOOKS: '/book/admin/get?query=',
            ADD_BOOK: '/book/admin/add',
            UPDATE_BOOK: '/book/admin/update',
            DELETE_BOOK: '/book/admin/delete',
            GET_BOOK_LINKED_COURSES: '/book/admin/linked_courses?bookId=',
            ARCHIVE_BOOK: '/book/admin/archive',
            UNARCHIVE_BOOK: '/book/admin/unarchive',
            // Books Inventory
            GET_BOOK_INVENTORY: '/book/admin/inventory/get?bookId=',
            ADD_BOOK_INVENTORY: '/book/admin/inventory/add',
            UPDATE_BOOK_INVENTORY: '/book/admin/inventory/update',
            DELETE_BOOK_INVENTORY: '/book/admin/inventory/delete',
            GET_BOOKS_INVENTORY_REPORT: '/book/admin/inventory/report',
            // Book Transaction
            GET_BOOK_TRANSACTIONS: '/book/admin/transaction/get_all',
            GET_BOOK_TRANSACTION: '/book/admin/transaction/get?transactionId=',
            GET_BOOK_TRANSACTION_STATUS_LIST:
                '/book/admin/transaction/get_status_list',
            GET_BOOK_TRANSACTION_COURIER_PARTNER:
                '/book/admin/transaction/get_courier_partners',
            UPDATE_BOOK_TRANSACTION_STATUS:
                '/book/admin/transaction/update/status',
            UPDATE_BOOK_TRANSACTION_COURIER_DETAILS:
                '/book/admin/transaction/update/courier_details',
            GET_BOOK_TRANSACTION_STATUS_LEDGER:
                '/book/admin/transaction/get/status_ledger?transactionId=',
            DELETE_BOOK_TRANSACTION: '/book/admin/transaction/delete',
            UPDATE_BOOK_TRANSACTION_STATUS_TO_ADDRESS_CONFIRMATION:
                '/book/admin/transaction/update_status_to_add_confirmation',
            GET_BOOK_TRANSACTION_ITEMS:
                '/book/admin/transaction/items/get?transactionId=',
            DELETE_BOOK_TRANSACTION_ITEM: '/book/admin/transaction/item/delete',
            // Books In Course
            GET_BOOKS_IN_COURSE: '/mp/admin/course/book/get?courseId=',
            ADD_BOOK_TO_COURSE: '/mp/admin/course/book/add',
            REMOVE_BOOK_TO_COURSE: '/mp/admin/course/book/remove',
            REORDER_BOOK_TO_COURSE: '/mp/admin/course/book/reorder',
            ENABLE_BOOK_TO_COURSE: '/mp/admin/course/book/enable',
            DISABLE_BOOK_TO_COURSE: '/mp/admin/course/book/disable',
            ENABLE_BOOK_IN_COURSE_VISIBLE_TO_STUDENT:
                '/mp/admin/course/book/visible_to_student/enable',
            DISABLE_BOOK_IN_COURSE_VISIBLE_TO_STUDENT:
                '/mp/admin/course/book/visible_to_student/disable',
        },
        COURIER: {
            // Courier
            GET_STATUS_LIST: '/courier/admin/get_status_list',
            GET_STATUS_ENUM_LIST: '/courier/admin/get_status_enums',
            GET_COURIERS: '/courier/admin/get_all',
            GET_COURIER: '/courier/admin/get?uid=',
            GET_COURIER_PACKAGE_TYPE_LIST:
                '/courier/admin/get_package_type_list',
            UPDATE_COURIER_PACKAGET_TYPE: '/courier/admin/update_package_type',
            SPLIT_COURIER: '/courier/admin/split_courier',
            MERGE_OPEN_COURIERS: '/courier/admin/merge_open_couriers',
            UPDATE_ALL_DETAILS_FROM_PARTNER:
                '/courier/admin/update_all_details_from_partner',
            UPDATE_COURIER_STATUS: '/courier/admin/update_status',
            DELETE_COURIER: '/courier/admin/delete',
            UPDATE_COURIER_ADDRESS: '/courier/admin/update_address',
            GET_OPEN_ITEMS_LIST: '/courier/admin/open_items_list',
            GET_LABEL_GENERATED_PARTNER_SPLIT:
                '/courier/admin/label_generated_partners_split',
            UPDATE_SEQUENCE: '/courier/admin/update_sequence',
            // Items
            GET_ITEMS: '/courier/admin/items?uid=',
            // Label
            GET_ITEMS_LIST_LABEL: '/courier/admin/items_list_label?uid=',
            GET_SHIPPING_LABEL: '/courier/admin/shipping_label?uid=',
            MARK_PACKED: '/courier/admin/mark_packed',
        },
        COLLEGE: {
            GET_COLLEGES: '/college/admin/get',
            ADD_COLLEGE: '/college/admin/add',
            UPDATE_COLLEGE: '/college/admin/update',
            DELETE_COLLEGE: '/college/admin/delete',
            // Admins
            GET_COLLGE_ADMINS: '/college/admin/admins/get?collegedUid=',
            ADD_COLLEGE_ADMIN: '/college/admin/admins/add',
            DELETE_COLLEGE_ADMIN: '/college/admin/admins/delete',
        },
        ATTENDANCE: {
            // Courses
            GET_ATTENDANCE_COURSES: '/attendance/admin/course/get',
            ADD_ATTENDANCE_COURSE: '/attendance/admin/course/add',
            DELETE_ATTENDANCE_COURSE: '/attendance/admin/course/delete',
            UPDATE_ATTENDANCE_COURSE_COLLEGE:
                '/attendance/admin/course/update_college',
            GET_COURSE_USERS_FOR_ATTENDANCE:
                '/attendance/admin/course/users?courseUid=',
            GET_COURSE_USERS_INFO:
                '/attendance/admin/course/users_info?courseUid=',
            UPDATE_COURSE_USER_INFO:
                '/attendance/admin/course/update_user_info',
            GET_COURSE_REPORT: '/attendance/admin/course/report?courseUid=',
            // Dates
            GET_ATTENDANCE_DATES: '/attendance/admin/date/get?courseUid=',
            ADD_ATTENDANCE_DATE: '/attendance/admin/date/add',
            DELETE_ATTENDANCE_DATE: '/attendance/admin/date/delete',
            // Users
            GET_ATTENDANCE_USERS: '/attendance/admin/user/get?dateUid=',
            UPDATE_ATTENDANCE_USER_STATUS:
                '/attendance/admin/user/update_status',
            GET_ATTENDANCE_USER_STATUS_LIST:
                '/attendance/admin/user/status_list',
        },
        PINCODE: {
            GET_PINCODES: '/pincode/admin/get?pincodeFilter=',
            ADD_PINCODE: '/pincode/admin/add',
            UPDATE_PINCODE: '/pincode/admin/update',
            DELETE_PINCODE: '/pincode/admin/delete',
        },
        IL: {
            // Roles
            GET_EMP_ROLES: '/il/admin/employee_roles/get',
            ADD_EMP_ROLE: '/il/admin/employee_roles/add',
            UPDATE_EMP_ROLE: '/il/admin/employee_roles/update',
            DELETE_EMP_ROLE: '/il/admin/employee_roles/delete',
            // Employees
            GET_EMPLOYEES: '/il/admin/employee/get?filter=',
            SEARCH_EMPLOYEE: '/il/admin/employee/search?query=',
            ADD_EMPLOYEE: '/il/admin/employee/add',
            UPDATE_EMPLOYEE: '/il/admin/employee/update',
            DELETE_EMPLOYEE: '/il/admin/employee/delete',
            ENABLE_EMPLOYEE: '/il/admin/employee/enable',
            DISABLE_EMPLOYEE: '/il/admin/employee/disable',
            // Leaves
            GET_LEAVE_BALANCE: '/il/admin/leaves/balance?userId=',
            GET_LEAVE_BALANCE_ALL: '/il/admin/leaves/balance_all?year=',
            GET_LEAVE_HISTORY: '/il/admin/leaves/history?userId=',
            ADD_LEAVE: '/il/admin/leaves/add',
            ADD_LEAVE_HALF_DAY: '/il/admin/leaves/add_half_day',
            UPDATE_LEAVE: '/il/admin/leaves/update_leave',
            CREDIT_LEAVES: '/il/admin/leaves/credit',
            DEBIT_LEAVES: '/il/admin/leaves/debit',
            DELETE_LEAVE: '/il/admin/leaves/delete',
            GET_CREDIT_LEAVE_TYPES: '/il/admin/leaves/credit_types',
            GET_DEBIT_LEAVE_TYPES: '/il/leaves/types_add',
            // Logins
            GET_LOGIN_DATA: '/il/admin/login/get',
            ADD_LOGIN_DATA: '/il/admin/login/add',
            UPDATE_LOGIN_DATA: '/il/admin/login/update',
            DELETE_LOGIN_DATA: '/il/admin/login/delete',
            GET_ALL_LOGINS_BY_DATE:
                '/il/admin/login/get_all_by_date?loginDate=',
            // Comp Offs
            GET_COMP_OFF_STATUS_FILTERS: '/il/comp_off/status_type_filters',
            GET_COMP_OFFS: '/il/admin/comp_off/get',
            APPROVE_COMP_OFF: '/il/admin/comp_off/approve',
            REJECT_COMP_OFF: '/il/admin/comp_off/reject',
            DELETE_COMP_OFF: '/il/admin/comp_off/delete',
            // Holidays
            GET_HOLIDAYS: '/il/admin/holidays/get?year=',
            ADD_HOLIDAY: '/il/admin/holidays/add',
            UPDATE_HOLIDAY: '/il/admin/holidays/update',
            DELETE_HOLIDAY: '/il/admin/holidays/delete',
            // Policies
            GET_POLICIES: '/il/admin/policy/get',
            ADD_POLICY: '/il/admin/policy/add',
            UPDATE_POLICY: '/il/admin/policy/update',
            DELETE_POLICY: '/il/admin/policy/delete',
            REORDER_POLICIES: '/il/admin/policy/reorder',
        },
        WEBINAR: {
            DOWNLOAD_WEBINAR_LEADS: '/webinar/admin/download_leads',
            // CFA
            GET_CFA_PAGE_DATA: '/webinar/admin/cfa/data',
            UPDATE_CFA_PAGE_DATA: '/webinar/admin/cfa/update_data',
        },
        CLOUD_PHONE: {
            CALL_INTERNAL_LEAD: '/cloud_phone/admin/call_internal_lead',
            CALL_EXTERNAL_LEAD: '/cloud_phone/admin/call_external_lead',
            REFRESH_CALL_STATUS: '/cloud_phone/admin/refresh_call_status',
            GET_MY_INCOMING_RINGING_CALLS:
                '/cloud_phone/admin/get_my_incoming_ringing_calls',
            GET_CALLS: '/cloud_phone/admin/get_calls',
            ADD_CALL_RECORD: '/cloud_phone/admin/add_call_record',
        },
        DEEPLINK: {
            GET_LINKS: '/dl/admin/get?filter=',
            ADD_LINK: '/dl/admin/add',
            UPDATE_LINK: '/dl/admin/update',
            UPDATE_LINK_ID: '/dl/admin/update_link_id',
            DELETE_LINK: '/dl/admin/delete',
        },
        REPORTS: {
            DOWNLOAD_NOT_PURCHASED_SUBSEQUENLTY_UESRS:
                '/report/admin/download_not_purchased_subsequently',
        },
        NUDGE: {
            // Study Mins Course Per Day
            GET_STUDY_MINS_COURSE_PER_DAY:
                '/nudge/admin/study_mins_course_per_day/get',
            ADD_STUDY_MINS_COURSE_PER_DAY:
                '/nudge/admin/study_mins_course_per_day/add',
            UPDATE_STUDY_MINS_COURSE_PER_DAY:
                '/nudge/admin/study_mins_course_per_day/update',
            DELETE_STUDY_MINS_COURSE_PER_DAY:
                '/nudge/admin/study_mins_course_per_day/delete',
            // Notifications
            GET_NOTIFICATIONS: '/nudge/admin/notification/get',
            ADD_NOTIFICATION: '/nudge/admin/notification/add',
            UPDATE_NOTIFICATION: '/nudge/admin/notification/update',
            DELETE_NOTIFICATION: '/nudge/admin/notification/delete',
            ENABLE_NOTIFICATION: '/nudge/admin/notification/enable',
            DISABLE_NOTIFICATION: '/nudge/admin/notification/disable',
            TEST_NOTIFICATION: '/nudge/admin/notification/test',
            GET_NOTIFICATION_SENDING_PATTERN_SUB_TYPES_1:
                '/nudge/admin/notification/sending_pattern_sub_types_1?sendingPattern=',
            GET_NOTIFICATION_SENDING_DAYS:
                '/nudge/admin/notification/sending_days?sendingPattern=',
        },
        RESULTS: {
            // Course Config
            GET_COURSE_CONFIG: '/user_result/admin/course_config/get',
            ADD_COURSE_CONFIG: '/user_result/admin/course_config/add',
            UPDATE_COURSE_CONFIG: '/user_result/admin/course_config/update',
            DELETE_COURSE_CONFIG: '/user_result/admin/course_config/delete',
            REORDER_COURSE_CONFIG: '/user_result/admin/course_config/reorder',
            // Results
            GET_RESULTS: '/user_result/admin/results/get',
            GET_RESULTS_BY_USER:
                '/user_result/admin/results/get_by_user?userId=',
            GET_ADD_RESULT_PARAMS: '/user_result/admin/results/get_add_params',
            ADD_RESULT: '/user_result/admin/results/add',
            UPDATE_RESULT: '/user_result/admin/results/update',
            DELETE_RESULT: '/user_result/admin/results/delete',
            DELETE_RESULT_COURSE: '/user_result/admin/results/course/delete',
            // Comments
            GET_COMMENTS: '/user_result/admin/comments/get?resultUid=',
            ADD_COMMENTS: '/user_result/admin/comments/add',
            UPDATE_COMMENTS: '/user_result/admin/comments/update',
            DELETE_COMMENTS: '/user_result/admin/comments/delete',
            // Report & Stats
            GET_DOWNLOAD_FILTERS: '/user_result/admin/download_filters',
            DOWNLOAD_RESULTS: '/user_result/admin/download',
        },
        TICKETS: {
            // Tickets
            GET: '/ticket/admin/get',
            GET_STATUS_LIST: '/ticket/admin/status_list',
            ADD: '/ticket/admin/add',
            UPDATE: '/ticket/admin/update',
            UPDATE_STATUS: '/ticket/admin/update_status',
            DELETE: '/ticket/admin/delete',
            ASSIGN: '/ticket/admin/assign',
            GET_TICKET_BY_ID: '/ticket/admin/get_by_id?uid=',
            UPDATE_LINKED_USER: '/ticket/admin/update_linked_user',
            CLEAR_LINKED_USER: '/ticket/admin/clear_linked_user',
            ADD_TICKET_FORM_FORUM_TOPIC: '/ticket/admin/add/forum_topic',
            MARK_TICKET_PRIVATE: '/ticket/admin/mark_private',
            MARK_TICKET_PUBLIC: '/ticket/admin/mark_public',
            // Comments
            GET_COMMENTS: '/ticket/admin/comments/get?uid=',
            ADD_COMMENTS: '/ticket/admin/comments/add',
            UPDATE_COMMENTS: '/ticket/admin/comments/update',
            DELETE_COMMENTS: '/ticket/admin/comments/delete',
            PIN_COMMENTS: '/ticket/admin/comments/pin',
            UNPIN_COMMENTS: '/ticket/admin/comments/unpin',
            MARK_COMMENTS_PRIVATE: '/ticket/admin/comments/mark_private',
            MARK_COMMENTS_PUBLIC: '/ticket/admin/comments/mark_public',
            // Stats
            GET_OPEN_TICKET_STATS: '/ticket/admin/stats/open_tickets',
        },
    },
}
